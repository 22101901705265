import React from 'react'
import styled, { css } from 'styled-components'
import { ReactComponent as Telegram } from 'images/socials/Telegram.svg'
import { ReactComponent as Medium } from 'images/socials/Medium.svg'
import { ReactComponent as Twitter } from 'images/socials/Twitter.svg'
import { ReactComponent as Discord } from 'images/socials/Discord.svg'
import { ReactComponent as Youtube } from 'images/socials/Youtube.svg'
import {
  DISCORD_LINK,
  MEDIUM_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
} from 'configs/app'

export default function Socials() {
  return (
    <Root>
      <SocialList>
        <Follow>Follow:</Follow>
        <SocialLink href={TELEGRAM_LINK} target={'_blank'}>
          <Telegram />
        </SocialLink>
        <SocialLink href={MEDIUM_LINK} target={'_blank'}>
          <Medium />
        </SocialLink>
        <SocialLink href={TWITTER_LINK} target={'_blank'}>
          <Twitter />
        </SocialLink>
        <SocialLink href={DISCORD_LINK} target={'_blank'}>
          <Discord />
        </SocialLink>
        <SocialLink href={YOUTUBE_LINK} target={'_blank'}>
          <Youtube />
        </SocialLink>
      </SocialList>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  display: flex;
  ${({ theme }) => theme.adaptive.main.xsm} {
    margin-top: 20px;
  }
`

const SocialList = styled.div`
  display: flex;
  align-items: center;
`

const Follow = styled.div`
  margin-right: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 11px;
    line-height: 9px;
    margin-right: 8px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 12px;
    line-height: 10px;
    margin-right: 7px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    line-height: 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    line-height: 7px;
    margin-right: 6px;
  }
`

const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 4px;
  background: #fff;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 28px;
    height: 28px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 24px;
    height: 24px;
    margin-right: 3px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 20px;
    height: 20px;
  }
  &:last-child {
    margin-right: 0;
  }

  svg {
    display: block;
    max-width: 19px;
    max-height: 13px;
    transition: 0.4s;
    fill: #000000;
    ${({ theme }) => theme.adaptive.main.xl} {
      max-width: 17px;
      max-height: 11px;
    }
    ${({ theme }) => theme.adaptive.main.slg} {
      max-width: 14px;
      max-height: 10px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      max-width: 12px;
      max-height: 8px;
    }
  }
  &:hover {
    svg {
      fill: #6078ff;
    }
  }
`
