import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

interface Props {
  onBurgerClick(): void

  active: boolean
  dark?: boolean
}

interface BurgerProps {
  active?: boolean
  dark?: boolean
}

const HeaderBurger: React.FunctionComponent<Props> = (props) => {
  const [animationStart, setAnimationStart] = useState(false)
  const [isAnimStarted] = useState(false)

  const burgerNode = useRef(null)

  const toggleBurger = () => {
    props.onBurgerClick()
    if (!isAnimStarted) {
      return animationStart ? setAnimationStart(false) : setAnimationStart(true)
    }
  }

  return (
    <BurgerAnimayionStyles>
      <CSSTransition
        nodeRef={burgerNode}
        in={animationStart}
        classNames="burger-anim"
        timeout={300}
      >
        <Burger
          onClick={toggleBurger}
          ref={burgerNode}
          className={`burger`}
          active={props.active}
          dark={props.dark}
        >
          <BurgerLine />
          <BurgerLine />
          <BurgerLine />
        </Burger>
      </CSSTransition>
    </BurgerAnimayionStyles>
  )
}

export default HeaderBurger

const BurgerLine = styled.div`
  position: relative;
  width: 100%;
  height: 1px;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
`

const Burger = styled.div<BurgerProps>`
  position: relative;
  z-index: 5;
  width: 22px;
  height: 22px;
  padding: 8px 6px;
  border-radius: 50%;
  background: ${({ active }) => (active ? '#ffffff' : '#000000')};
  ${BurgerLine} {
    background: ${({ active }) => (active ? '#000000' : '#ffffff')};
  }
`

const ROTATE_LINE_DEG = '45deg'
const TRANSLATE_LINE_DEG_MOB = '3px'
const TRANSLATE_LINE_DEG_MOB_LAST = '3px'

const BurgerAnimayionStyles = styled.div`
  .burger {
    &.burger-anim-enter-active {
      div {
        &:nth-child(1) {
          transform: translateY(${TRANSLATE_LINE_DEG_MOB});
        }

        &:nth-child(3) {
          transform: translateY(-${TRANSLATE_LINE_DEG_MOB});
        }
      }
    }

    &.burger-anim-enter-done {
      div {
        &:nth-child(1) {
          transform: translateY(${TRANSLATE_LINE_DEG_MOB})
            rotate(-${ROTATE_LINE_DEG});
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: translateY(-${TRANSLATE_LINE_DEG_MOB_LAST})
            rotate(${ROTATE_LINE_DEG});
        }
      }
    }

    &.burger-anim-exit-active {
      div {
        &:nth-child(1) {
          transform: rotate(0) translateY(${TRANSLATE_LINE_DEG_MOB});
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(0) translateY(-${TRANSLATE_LINE_DEG_MOB});
        }
      }
    }

    div {
      transform-origin: center center;
    }
  }
`
