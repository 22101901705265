import styled from 'styled-components'
import { Container } from 'theme/globalStyle'

export const MoreLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #727172;
  transition: color 0.3s ease;
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 15px;
    svg {
      width: 13px;
      margin-left: 11px;
    }
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 13px;
    svg {
      width: 9px;
      margin-left: 8px;
    }
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 18px;
    svg {
      width: 16px;
      margin-left: 14px;
    }
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    font-size: 16px;
    svg {
      width: 16px;
      margin-left: 14px;
    }
  }
  &:hover {
    color: #6078ff;
    svg {
      fill: #6078ff;
    }
  }
  svg {
    margin-left: 15px;
    fill: #727172;
    transition: fill 0.3s ease;
    height: auto;
  }
`

export const TitleWrapper = styled.div`
  border-bottom: 1px dashed #b6b6b6;
  border-top: 1px dashed #b6b6b6;
  padding: 2px 0 6px 0;
  ${({ theme }) => theme.adaptive.main.smd} {
    border: 0;
  }
  ${Container} {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

export const MobileMoreLink = styled.div`
  display: none;
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
`
