import { createGlobalStyle } from 'styled-components'
// Regular - 400
import AeroportWoff from './fonts/Aeroport.woff'
import AeroportWoff2 from './fonts/Aeroport.woff2'
// Bold - 700
import AeroportBoldWoff from './fonts/Aeroport-Bold.woff'
import AeroportBoldWoff2 from './fonts/Aeroport-Bold.woff2'
// Bold - 500
import AeroportMediumWoff from './fonts/Aeroport-Medium.woff'
import AeroportMediumWoff2 from './fonts/Aeroport-Medium.woff2'
// Bold Italic - 700
import AeroportBoldItalicWoff from './fonts/Aeroport-BoldItalic.woff'
import AeroportBoldItalicWoff2 from './fonts/Aeroport-BoldItalic.woff2'
// Light - 300
import AeroportLightWoff from './fonts/Aeroport-Light.woff'
import AeroportLightWoff2 from './fonts/Aeroport-Light.woff2'
// Light Italic - 300
import AeroportLightItalicWoff from './fonts/Aeroport-LightItalic.woff'
import AeroportLightItalicWoff2 from './fonts/Aeroport-LightItalic.woff2'

const Aeroport = {
  regular: {
    woff2: AeroportWoff2,
    woff: AeroportWoff,
  },
  bold: {
    woff2: AeroportBoldWoff2,
    woff: AeroportBoldWoff,
    italic: {
      woff2: AeroportBoldItalicWoff2,
      woff: AeroportBoldItalicWoff,
    },
  },
  medium: {
    woff2: AeroportMediumWoff2,
    woff: AeroportMediumWoff,
  },
  light: {
    woff2: AeroportLightWoff2,
    woff: AeroportLightWoff,
    italic: {
      woff2: AeroportLightItalicWoff2,
      woff: AeroportLightItalicWoff,
    },
  },
}

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Aeroport';
    src: local('Aeroport'),
    url(${Aeroport.regular.woff2}) format('woff2'),
    url(${Aeroport.regular.woff}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Aeroport';
    src: local('Aeroport'),
    url(${Aeroport.bold.woff2}) format('woff2'),
    url(${Aeroport.bold.woff}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Aeroport';
    src: local('Aeroport'),
    url(${Aeroport.bold.italic.woff2}) format('woff2'),
    url(${Aeroport.bold.italic.woff}) format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Aeroport';
    src: local('Aeroport'),
    url(${Aeroport.medium.woff2}) format('woff2'),
    url(${Aeroport.medium.woff}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Aeroport';
    src: local('Aeroport'),
    url(${Aeroport.light.woff2}) format('woff2'),
    url(${Aeroport.light.woff}) format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Aeroport';
    src: local('Aeroport'),
    url(${Aeroport.light.italic.woff2}) format('woff2'),
    url(${Aeroport.light.italic.woff}) format('woff');
    font-weight: 300;
    font-style: italic;
  }
`

export default Fonts
