import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import { Container, TitleH2 } from 'theme/globalStyle'
import tiersList from 'store/tiers'
import { ReactComponent as NumberOne } from 'images/index/tiers/numbers/1.svg'
import { ReactComponent as NumberFive } from 'images/index/tiers/numbers/5.svg'
import useWindowSize from 'helpers/utils/useWindowSize'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Dots from './dots'
import arcImage from 'images/index/tiers/arc.svg'
import arcDarkImage from 'images/index/tiers/arc-dark.svg'
import arcBlueImage from 'images/index/tiers/arc-blue.svg'
import mask from 'images/index/tiers/mask.svg'

const getTierNumberFz = (idx: number) => {
  switch (idx) {
    case 0:
      return css`
        font-size: 28px;
        ${({ theme }) => theme.adaptive.main.xl} {
          font-size: 24px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          font-size: 21px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          font-size: 18px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          font-size: 16px;
        }
      `
      break
    case 1:
      return css`
        font-size: 34px;
        ${({ theme }) => theme.adaptive.main.xl} {
          font-size: 30px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          font-size: 26px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          font-size: 22px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          font-size: 18px;
        }
      `
      break
    case 2:
      return css`
        font-size: 42px;
        ${({ theme }) => theme.adaptive.main.xl} {
          font-size: 36px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          font-size: 32px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          font-size: 28px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          font-size: 22px;
        }
      `
    case 3:
      return css`
        font-size: 48px;
        ${({ theme }) => theme.adaptive.main.xl} {
          font-size: 42px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          font-size: 36px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          font-size: 30px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          font-size: 26px;
        }
      `
      break
    case 4:
      return css`
        font-size: 52px;
        ${({ theme }) => theme.adaptive.main.xl} {
          font-size: 45px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          font-size: 38px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          font-size: 34px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          font-size: 28px;
        }
      `
      break
  }
}

const getNumber = (idx: number) => {
  switch (idx) {
    case 1:
      return (
        <>
          <NumberOne />
        </>
      )
      break
    case 2:
      return (
        <>
          <NumberOne />
          <NumberOne />
        </>
      )
      break
    case 3:
      return (
        <>
          <NumberOne />
          <NumberOne />
          <NumberOne />
        </>
      )
      break
    case 4:
      return (
        <>
          <NumberOne />
          <NumberFive />
        </>
      )
      break
    case 5:
      return (
        <>
          <NumberFive />
        </>
      )
      break
  }
}

function Tiers() {
  const { width } = useWindowSize()
  const [activeId, setActiveId] = useState(0)

  const items = tiersList.map((tier, idx) => {
    return (
      <Tier idx={idx} last={idx === 4} key={idx}>
        <BigImage last={idx === 4} bg={tier.image} />
        <Name>{tier.name}</Name>
        <IndexNumberWrapper idx={idx}>
          {tiersList.map((tier, idxNumber) => (
            <Circle active={idxNumber <= idx} key={idxNumber} />
          ))}
          {getNumber(5 - idx)}
        </IndexNumberWrapper>
        <ImagesWrapper name={tier.name}>
          <ImageWhiteHalf image={tier.image} />
          <ArcsWrapper>
            {Array.from(Array(idx), () => {
              return <Arc />
            })}
          </ArcsWrapper>
          <BottomImageWrapper image={tier.image}>
            {/* <Image src={tier.image} image={tier.image} /> */}
            <Arc />
            <Image idx={idx} image={tier.image} />
          </BottomImageWrapper>
        </ImagesWrapper>
        <Bottom>
          <StakeRequired>
            <span>HSTART</span> Stake required
          </StakeRequired>
          <StakeAmount idx={idx}>{tier.stakeAmount}</StakeAmount>
          <RowStats idx={idx}>
            <Stat>
              <StatName>Participation</StatName>
              <StatValue>{tier.participation}</StatValue>
            </Stat>
            <Stat>
              <StatName>Pool Weight</StatName>
              <StatValue>{tier.poolWeight}</StatValue>
            </Stat>
          </RowStats>
        </Bottom>
      </Tier>
    )
  })

  const settings = {
    centerMode: true,
    infinite: true,
    swipeToSlide: true,
    variableWidth: true,
    arrows: false,
    slidesToShow: 1,
    beforeChange(currentSlide: number, nextSlide: number) {
      setActiveId(nextSlide)
    },
  }

  const SliderList = (
    <SliderWrap>
      <Slider {...settings} className="slider">
        {items}
      </Slider>
      <DotsWrap>
        <Dots activeId={activeId} />
      </DotsWrap>
    </SliderWrap>
  )

  return (
    <Root>
      <Container>
        <TitleH2>
          <span>HStarter</span> Tiers
        </TitleH2>
        <TiersList>
          {(() => {
            if (width <= 880) {
              return SliderList
            }
            return items
          })()}
        </TiersList>
      </Container>
      <svg width="0" height="0">
        <clipPath id="myPath2">
          <path d="M0.368164 53.2441C35.5224 20.2256 82.8333 0 134.868 0C186.411 0 233.319 19.8451 268.368 52.3114V110H0.368164V53.2441Z" />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="xl">
          <path d="M0 47.4357C30.3009 18.0192 71.08 0 115.931 0C160.358 0 200.79 17.6802 231 46.6047V98H0V47.4357Z" />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="lg">
          <path d="M0 33.8826C26.2345 12.8708 61.5412 0 100.373 0C138.838 0 173.844 12.6287 200 33.2891V70H0V33.8826Z" />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="slg">
          <path d="M0 30.0103C22.5617 11.3999 52.9254 0 86.3209 0C119.401 0 149.506 11.1854 172 29.4846V62H0V30.0103Z" />
        </clipPath>
      </svg>
      <svg width="0" height="0">
        <clipPath id="md">
          <path d="M0 25.17C19.02 9.5612 44.6173 0 72.7705 0C100.658 0 126.037 9.38133 145 24.729V52H0V25.17Z" />
        </clipPath>
      </svg>
    </Root>
  )
}

export default Tiers

const Root = styled.div`
  position: relative;
  border-top: 1px dashed #b6b6b6;
  border-bottom: 1px dashed #b6b6b6;
  padding-top: 69px;
  padding-bottom: 87px;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding-top: 60px;
    padding-bottom: 80px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding-top: 51px;
    padding-bottom: 67px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding-top: 44px;
    padding-bottom: 57px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-top: 30px;
    padding-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding-top: 30px;
    padding-bottom: 75px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding-top: 30px;
    padding-bottom: 70px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1805px;
    height: 100%;
    background: #dfdde0;
  }
  ${TitleH2} {
    margin-bottom: 59px;
    ${({ theme }) => theme.adaptive.main.xl} {
      margin-bottom: 51px;
    }
    ${({ theme }) => theme.adaptive.main.slg} {
      margin-bottom: 38px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      margin-bottom: 29px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      margin-bottom: 49px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      margin-bottom: 32px;
    }
    ${({ theme }) => theme.adaptive.main.xsm} {
      margin-bottom: 35px;
    }
  }
  ${Container} {
    position: relative;
    z-index: 2;
  }
  .slick-active {
    transform: scale(1.1);
  }
  .slick-slide {
    transition: 0.4s;
  }
  .slick-list {
    overflow: unset !important;
  }
`

const TiersList = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.main.smd} {
    display: block;
  }
`

const Image = styled.div<{ image: string; idx: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: url(${({ image }) => image}) no-repeat center bottom;
  ${({ idx }) => {
    if (idx === 0)
      return css`
        background-size: 170px;
        ${({ theme }) => theme.adaptive.main.xl} {
          background-size: 147px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          background-size: 107px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          background-size: 89px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          background-size: 69px;
        }
        ${({ theme }) => theme.adaptive.main.smd} {
          background-size: 95px;
        }
        ${({ theme }) => theme.adaptive.main.sm} {
          background-size: 68px;
        }
      `
    else if (idx === 1)
      return css`
        background-size: 207px;
        ${({ theme }) => theme.adaptive.main.xl} {
          background-size: 176px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          background-size: 135px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          background-size: 120px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          background-size: 87px;
        }
        ${({ theme }) => theme.adaptive.main.smd} {
          background-size: 124px;
        }
        ${({ theme }) => theme.adaptive.main.sm} {
          background-size: 89px;
        }
      `
    else if (idx === 2)
      return css`
        background-size: 128px;
        ${({ theme }) => theme.adaptive.main.xl} {
          background-size: 109px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          background-size: 74px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          background-size: 60px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          background-size: 49px;
        }
        ${({ theme }) => theme.adaptive.main.smd} {
          background-size: 77px;
        }
        ${({ theme }) => theme.adaptive.main.sm} {
          background-size: 48px;
        }
      `
    else if (idx === 3)
      return css`
        background-size: 270px;
        ${({ theme }) => theme.adaptive.main.xl} {
          background-size: 233px;
        }
        ${({ theme }) => theme.adaptive.main.lg} {
          background-size: 204px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          background-size: 179px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          background-size: 153px;
        }
        ${({ theme }) => theme.adaptive.main.smd} {
          background-size: 179px;
        }
        ${({ theme }) => theme.adaptive.main.sm} {
          background-size: 153px;
        }
      `
  }}
`

const BottomImageWrapper = styled.div<{ image: string }>`
  position: absolute;
  width: 100%;
  height: 70px;
  /* overflow: hidden; */
  left: 0;
  bottom: -1px;
  text-align: center;
  clip-path: url('#myPath2');
  z-index: 3;
  transform: translate3d(0, 0, 0);
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 60px;
    clip-path: url('#xl');
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 54px;
    clip-path: url('#lg');
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 47px;
    clip-path: url('#slg');
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 39px;
    clip-path: url('#md');
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    height: 47px;
    clip-path: url('#slg');
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 39px;
    clip-path: url('#md');
  }
`

const ImageWhiteHalf = styled.div<{ image: string }>`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background: url(${({ image }) => image}) no-repeat center;
  background-size: contain;
  mix-blend-mode: plus-lighter;
  z-index: 3;
`

const ImagesWrapper = styled.div<{ name: string }>`
  position: relative;
  height: 100%;
  width: 100%;
  ${({ name }) => {
    if (name === 'internet')
      return css`
        ${Image} {
          background-position: bottom -13px left;
        }
        ${ImageWhiteHalf} {
          background-position: bottom -13px left;
          position: relative;
        }
        ${BottomImageWrapper} {
          height: 100px;
          ${({ theme }) => theme.adaptive.main.xl} {
            height: 86px;
          }
          ${({ theme }) => theme.adaptive.main.lg} {
            height: 70px;
          }
          ${({ theme }) => theme.adaptive.main.slg} {
            height: 62px;
          }
          ${({ theme }) => theme.adaptive.main.md} {
            height: 52px;
          }
          ${({ theme }) => theme.adaptive.main.smd} {
            height: 62px;
          }
          ${({ theme }) => theme.adaptive.main.sm} {
            height: 52px;
          }
        }
        ${ArcsWrapper} {
          height: 100px;
          ${({ theme }) => theme.adaptive.main.xl} {
            height: 86px;
          }
          ${({ theme }) => theme.adaptive.main.lg} {
            height: 70px;
          }
          ${({ theme }) => theme.adaptive.main.slg} {
            height: 62px;
          }
          ${({ theme }) => theme.adaptive.main.md} {
            height: 52px;
          }
          ${({ theme }) => theme.adaptive.main.smd} {
            height: 62px;
          }
          ${({ theme }) => theme.adaptive.main.sm} {
            height: 52px;
          }
        }
      `
  }}
`

const StakeAmount = styled.div<{ idx: number }>`
  ${({ idx }) => getTierNumberFz(idx)}
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  text-align: center;
  margin: 10px 0;
`

const Name = styled.div`
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -1.3px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 12px 0 0 17px;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 19px;
    line-height: 28px;
    letter-spacing: -1.2px;
    padding: 10px 0 0 15px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.9px;
    padding: 8px 0 0 11px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: -0.7px;
    padding: 7px 0 0 9px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.9px;
    padding: 8px 0 0 11px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.7px;
    padding: 7px 0 0 10px;
  }
`

const Arc = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${arcImage}) no-repeat center bottom;
  background-size: cover;
`

const ArcsWrapper = styled.div`
  position: absolute;
  height: 70px;
  width: 100%;
  left: 0;
  bottom: 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 60px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 54px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 47px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 39px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    height: 47px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 39px;
  }
  ${Arc} {
    z-index: 2;
    opacity: 0.3;
    &:first-child {
      bottom: 8px;
      ${({ theme }) => theme.adaptive.main.xl} {
        bottom: 6px;
      }
    }
    &:nth-child(2) {
      bottom: 16px;
      ${({ theme }) => theme.adaptive.main.xl} {
        bottom: 12px;
      }
    }
    &:nth-child(3) {
      bottom: 24px;
      ${({ theme }) => theme.adaptive.main.xl} {
        bottom: 18px;
      }
    }
    &:nth-child(4) {
      bottom: 32px;
      ${({ theme }) => theme.adaptive.main.xl} {
        bottom: 24px;
      }
    }
  }
`

const Bottom = styled.div`
  position: relative;
  background: #e4e3e5;
  padding: 10px 0 25px 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 9px 0 20px 0;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 8px 0 18px 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 7px 0 17px 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 6px 0 13px 0;
  }
`

const StakeRequired = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #393939;
  text-align: center;
  text-transform: uppercase;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 9px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 8px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 9px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 7px;
  }
  span {
    font-weight: 700;
    color: #8193f8;
  }
`

const Stat = styled.div`
  text-align: center;
  margin: 0 7px;
`

const StatValue = styled.div`
  width: 78px;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  border-radius: 10px;
  background: #d7d8e8;
  padding: 2px 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 10px;
    border-radius: 9px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 50px;
    font-size: 8px;
    border-radius: 6px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 42px;
    font-size: 7px;
    border-radius: 5px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 50px;
    font-size: 8px;
    border-radius: 6px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 40px;
    font-size: 6px;
    border-radius: 5px;
  }
`

const RowStats = styled.div<{ idx?: number }>`
  display: flex;
  justify-content: center;
  ${({ idx }) => {
    if (idx === 4)
      return css`
        ${Stat}:nth-child(1) {
          ${StatValue} {
            font-size: 9px;
            padding: 4px 0;
            ${({ theme }) => theme.adaptive.main.xl} {
              font-size: 8px;
              padding: 4px 0 3px;
            }
            ${({ theme }) => theme.adaptive.main.slg} {
              font-size: 6px;
              padding: 4px 0 2px;
            }
            ${({ theme }) => theme.adaptive.main.md} {
              font-size: 5px;
            }
            ${({ theme }) => theme.adaptive.main.smd} {
              font-size: 5px;
              padding: 5px 0 3px;
            }
            ${({ theme }) => theme.adaptive.main.sm} {
              padding: 4px 0 1px;
            }
          }
        }
      `
  }}
`

const StatName = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 130%;
  margin-bottom: 10px;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 7px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 8px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 6px;
  }
`

const IndexNumberWrapper = styled.div<{ idx: number }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 11px;
  top: 13px;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: ${({ idx }) => `rgba(0,0,0, ${0.2 + 0.2 * idx})`};
  ${({ theme }) => theme.adaptive.main.slg} {
    right: 8px;
    top: 8px;
    width: 21px;
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    right: 6px;
    top: 7px;
    width: 18px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    right: 8px;
    top: 8px;
    width: 21px;
    height: 21px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    right: 6px;
    top: 6px;
    width: 17px;
    height: 17px;
  }
  svg {
    fill: #fff;
    margin-right: 1px;
    &:last-child {
      margin-right: 0;
    }
  }
`

const Circle = styled.div<{ active: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border: 1px solid ${({ active }) => (active ? '#A0AEFF' : '#4d60cc')};
  border-radius: 50%;
  &:nth-child(1) {
    width: 50px;
    height: 50px;
    ${({ theme }) => theme.adaptive.main.slg} {
      width: 31px;
      height: 31px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 27px;
      height: 27px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 31px;
      height: 31px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 25px;
      height: 25px;
    }
  }
  &:nth-child(2) {
    width: 68px;
    height: 68px;
    ${({ theme }) => theme.adaptive.main.slg} {
      width: 43px;
      height: 43px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 36px;
      height: 36px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 43px;
      height: 43px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 35px;
      height: 35px;
    }
  }
  &:nth-child(3) {
    width: 85px;
    height: 85px;
    ${({ theme }) => theme.adaptive.main.slg} {
      width: 55px;
      height: 55px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 46px;
      height: 46px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 55px;
      height: 55px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 44px;
      height: 44px;
    }
  }
  &:nth-child(4) {
    width: 103px;
    height: 103px;
    ${({ theme }) => theme.adaptive.main.slg} {
      width: 66px;
      height: 66px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 56px;
      height: 56px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 66px;
      height: 66px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 53px;
      height: 53px;
    }
  }
  &:nth-child(5) {
    width: 121px;
    height: 121px;
    ${({ theme }) => theme.adaptive.main.slg} {
      width: 78px;
      height: 78px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 66px;
      height: 66px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 78px;
      height: 78px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      width: 63px;
      height: 63px;
    }
  }
`

const SliderWrap = styled.div`
  width: 100%;
  position: relative;
`

const DotsWrap = styled.div`
  position: absolute;
  left: 0;
  bottom: -40px;
  width: 100%;
`

const Tier = styled.div<{ idx: number; last: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 267px;
  height: 400px;
  background: #6078ff;
  overflow: hidden;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 231px;
    height: 347px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 200px;
    height: 300px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 172px;
    height: 257px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 145px;
    height: 217px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    display: flex !important;
    width: 172px !important;
    height: 257px;
    margin: 20px 10px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 137px !important;
    height: 206px;
  }
  ${StakeAmount} {
    opacity: ${({ idx }) => {
      const idxOpacity = 0.2
      const opacityStep = idxOpacity * (idx + 2)
      return opacityStep > 1 ? 1 : opacityStep
    }};
  }
  ${({ last }) => {
    if (last)
      return css`
        ${Bottom} {
          background: transparent;
          z-index: 2;
        }
        ${ImagesWrapper} {
          z-index: 1;
        }
        ${StakeAmount}, ${StakeRequired} span, ${StakeRequired}, ${StatName} {
          color: #ffffff;
        }
        ${StakeRequired} {
          font-weight: 300;
        }
        ${StakeRequired} span {
          font-weight: bold;
        }
        ${StatValue} {
          background: #ffffff;
        }
        ${IndexNumberWrapper} {
          background: #fff;
          svg {
            fill: #000;
          }
        }
        ${BottomImageWrapper} {
          bottom: 7px;
          height: 105px;
          ${({ theme }) => theme.adaptive.main.xl} {
            bottom: 4px;
            height: 86px;
          }
          ${({ theme }) => theme.adaptive.main.lg} {
            height: 70px;
          }
          ${({ theme }) => theme.adaptive.main.slg} {
            height: 62px;
          }
          ${({ theme }) => theme.adaptive.main.md} {
            height: 52px;
          }
          ${({ theme }) => theme.adaptive.main.smd} {
            height: 62px;
          }
          ${({ theme }) => theme.adaptive.main.sm} {
            height: 52px;
          }
          ${Arc} {
            display: none;
          }
          ${Image} {
            background-size: 342px;
          }
        }
        ${ImageWhiteHalf} {
          display: none;
        }
        ${Image} {
          background-image: url(${arcBlueImage});
          background-size: 420px;
        }
        ${ArcsWrapper} {
          height: 110px;
          ${({ theme }) => theme.adaptive.main.xl} {
            height: 86px;
          }
          ${({ theme }) => theme.adaptive.main.lg} {
            height: 70px;
          }
          ${({ theme }) => theme.adaptive.main.slg} {
            height: 62px;
          }
          ${({ theme }) => theme.adaptive.main.md} {
            height: 52px;
          }
          ${({ theme }) => theme.adaptive.main.smd} {
            height: 62px;
          }
          ${({ theme }) => theme.adaptive.main.sm} {
            height: 52px;
          }
          ${Arc} {
            /* background: #3c4ca6; */
            background-image: url(${arcDarkImage});
            opacity: 0.3;
          }
        }
      `
  }}
`

const BigImage = styled.div<{ last: boolean; bg: string }>`
  position: absolute;
  display: ${({ last }) => (last ? 'block' : 'none')};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ bg }) => (bg ? css`url("${bg}") center no-repeat` : '')};
  background-size: cover;
  mix-blend-mode: multiply;
  z-index: 2;
`
