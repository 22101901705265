export const breakpoints = {
  xl: 1680,
  lg: 1440,
  slg: 1240,
  md: 1060,
  smd: 880,
  sm: 580,
  xsm: 380,
}

const theme = {
  adaptive: {
    main: {
      xl: `@media screen and (max-width: ${breakpoints.xl}px)`,
      lg: `@media screen and (max-width: ${breakpoints.lg}px)`,
      slg: `@media screen and (max-width: ${breakpoints.slg}px)`,
      md: `@media screen and (max-width: ${breakpoints.md}px)`,
      smd: `@media screen and (max-width: ${breakpoints.smd}px)`,
      sm: `@media screen and (max-width: ${breakpoints.sm}px)`,
      xsm: `@media screen and (max-width: ${breakpoints.xsm}px)`,
    },
    form: {
      sm: `@media screen and (max-width: ${breakpoints.sm}px), screen and (max-height: 740px)`,
      xsm: `@media screen and (max-width: ${breakpoints.xsm}px)`,
    },
  },
}

export default theme
