import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, TitleH2 } from 'theme/globalStyle'
import getLatestYoutubePosts from 'api/youtube'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import playIcon from 'images/index/youtube/play.svg'
import { ReactComponent as MoreLinkArrow } from 'images/index/news/arrowMore.svg'
import { YOUTUBE_LINK } from 'configs/app'
import { MoreLink, TitleWrapper, MobileMoreLink } from './components'

function Youtube() {
  const [postsList, setPostsList] = useState<any[]>([])
  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await getLatestYoutubePosts()
      const slicedPosts = posts.data.slice(0, 3)
      slicedPosts.forEach((e: any, idx: number) => {
        e.idx = idx
        e.frameAppended = false
      })
      setPostsList(slicedPosts)
    }
    fetchPosts()
  }, [])

  const fetchYoutubeFrame = (e: React.MouseEvent<HTMLElement>, post: any) => {
    if (!post.frameAppended) {
      post.frameAppended = true
      setPostsList([...postsList])
      const videoWrapper = e.target as HTMLElement
      videoWrapper.innerHTML += `
    <iframe src="https://www.youtube.com/embed/${post.id}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    `
    }
  }

  if (!postsList.length) return null

  return (
    <Root>
      <TitleWrapper>
        <Container>
          <TitleH2>
            <span>Watch</span> about HStarter
          </TitleH2>
          <MoreLink href={YOUTUBE_LINK} target="_blank">
            All HStarter Videos <MoreLinkArrow />
          </MoreLink>
        </Container>
      </TitleWrapper>
      <Container>
        <NewsList>
          {postsList.map((post: any) => {
            return (
              <New>
                <Date>
                  Streamed live on{' '}
                  {format(parseISO(post.pubDate), 'dd MMMM yyyy')}
                </Date>
                <Description>{post.title}</Description>
                <Image
                  style={{
                    backgroundImage: post.frameAppended
                      ? 'url()'
                      : `url(${post.thumbnail}`,
                  }}
                  onClick={(e) => fetchYoutubeFrame(e, post)}
                >
                  <Play active={!post.frameAppended} />
                </Image>
                {/* <Tags>#Bitcoin #CryptoMarkets</Tags> */}
              </New>
            )
          })}
        </NewsList>
        <MobileMoreLink>
          <MoreLink href={YOUTUBE_LINK} target="_blank">
            All HStarter Videos <MoreLinkArrow />
          </MoreLink>
        </MobileMoreLink>
      </Container>
    </Root>
  )
}

export default Youtube

const Root = styled.div`
  border-bottom: 1px dashed #b6b6b6;
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 0 0 92px 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 0 0 24px 0;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding: 0 0 20px 0;
  }
  ${TitleWrapper} {
    ${MoreLink} {
      ${({ theme }) => theme.adaptive.main.smd} {
        display: none;
      }
    }
  }
`

const NewsList = styled.div`
  display: flex;
  gap: 143px;
  padding: 80px 0 35px 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 74px 0 31px 0;
    gap: 121px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 64px 0 21px 0;
    gap: 107px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 58px 0 32px 0;
    gap: 95px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 26px 0 17px 0;
    gap: 78px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 42px 0 0 0;
    display: block;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 40px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding: 40px 0 0 0;
  }
`

const Play = styled.div<{ active: boolean }>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  width: 54px;
  height: 54px;
  right: 26px;
  bottom: 21px;
  background: #6078ff;
  border: 1px solid #e4e3e5;
  border-radius: 10px;
  background-image: url(${playIcon});
  background-size: 19px 22px;
  background-repeat: no-repeat;
  background-position: center;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 47px;
    height: 47px;
    bottom: 18px;
    background-size: 16px 20px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 40px;
    height: 40px;
    bottom: 16px;
    right: 21px;
    background-size: 14px 17px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 35px;
    height: 35px;
    bottom: 13px;
    right: 15px;
    background-size: 12px 14px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 29px;
    height: 29px;
    bottom: 11px;
    right: 12px;
    background-size: 10px 12px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 75px;
    height: 75px;
    bottom: 20px;
    right: 34px;
    background-size: 26px 31px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 45px;
    height: 45px;
    bottom: 16px;
    right: 21px;
    background-size: 16px 18px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    width: 37px;
    height: 37px;
    bottom: 15px;
    right: 18px;
    background-size: 13px 15px;
  }
`

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 220px;
  background: #e4e3e5;
  border: 1px solid #000000;
  border-radius: 11px;
  margin-bottom: 14px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #000;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0);
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 11px;
  }
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 200px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 170px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 145px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    border-radius: 6px;
    height: 125px;
    margin-bottom: 7px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    border-radius: 10px;
    height: 312px;
    margin-bottom: 10px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 186px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    height: 155px;
  }
`

const New = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 33.333333%;
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 100%;
    margin-bottom: 56px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-bottom: 47px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    margin-bottom: 39px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    ${Image} {
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    }
    /* ${Play} {
    } */
  }
`

const Description = styled.p`
  margin-bottom: 22px;
  font-weight: 300;
  font-size: 20px;
  line-height: 114%;
  letter-spacing: -0.03em;
  color: #000000;
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 18px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 16px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 13px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 16px;
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-bottom: 15px;
  }
`

const Date = styled.div`
  font-weight: 900;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.2px;
  color: #727172;
  margin-bottom: 13px;
  ${({ theme }) => theme.adaptive.main.xl} {
    line-height: 16px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    line-height: 14px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    line-height: 12px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 6px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 14px;
    line-height: 12px;
    margin-bottom: 8px;
  }
`

// const Tags = styled.div`
//   font-weight: 900;
//   font-size: 14px;
//   line-height: 18px;

//   letter-spacing: -0.2px;
//   color: #727172;
//   ${({ theme }) => theme.adaptive.main.md} {
//     font-size: 12px;
//     line-height: 10px;
//   }
//   ${({ theme }) => theme.adaptive.main.smd} {
//     font-size: 14px;
//     line-height: 12px;
//   }
// `
