import React from 'react'
import { BrowserRouter as RouterReact, Routes, Route } from 'react-router-dom'
import Index from '../components/Index'

export default function Router() {
  return (
    <RouterReact>
      <Routes>
        <Route path="/" element={<Index />} />
      </Routes>
    </RouterReact>
  )
}
