import axios from 'axios'
import apiAuth from '../authData'
import { API_URL } from 'configs/app'

const fetchYoutubeFeed = async () => {
  const authData = apiAuth.auth
  return await axios.get(`${API_URL}/youtube/posts`, {
    auth: authData,
  })
}

export default fetchYoutubeFeed
