import React from 'react'
import styled from 'styled-components'
import useWindowSize from 'helpers/utils/useWindowSize'
import Banner from './Banner'
import { Container } from 'theme/globalStyle'
import bg from 'images/index/bg.png'
import videoBg from './bg-video.mp4'
import {
  APPLY_FOR_IDO,
  // NFT_LINK
} from 'configs/app'

interface MainProps {
  openClick: (val: boolean) => void
}

const Main: React.FunctionComponent<MainProps> = (props) => {
  const { width } = useWindowSize()
  return (
    <Root>
      <Video autoPlay loop muted>
        <source src={videoBg} type={'video/mp4'} />
      </Video>
      <Container>
        <Title>Innovation, Tokenized</Title>
        <Row>
          {/* <NFTButton href={NFT_LINK} target={'_blank'}>
            IDO <span>NFT</span> Sale
          </NFTButton> */}
          <Button href={APPLY_FOR_IDO} target={'_blank'}>
            Apply for IDO
          </Button>
          <Button onClick={() => props.openClick(true)}>Contact Us</Button>
        </Row>
        <BannerWrapper>
          <Banner />
        </BannerWrapper>
      </Container>
    </Root>
  )
}

export default Main

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &:before {
    /* ${({ theme }) => theme.adaptive.main.sm} {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 640px;
      height: 640px;
      background: url('${bg}') center no-repeat;
      background-size: cover;
    }

    ${({ theme }) => theme.adaptive.main.smm} {
      width: 428px;
      height: 428px;
    } */
  }
  ${Container} {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

const Video = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 946px;
  height: 947px;
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 830px;
    height: 831px;
    background-size: cover;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 100%;
  }
`

const Title = styled.h1`
  position: relative;
  font-weight: 700;
  font-size: 116px;
  line-height: 118px;
  text-align: center;
  letter-spacing: -8px;
  color: #000000;
  margin: 0;
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 100px;
    line-height: 89px;
    letter-spacing: -6px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 96px;
    line-height: 76px;
    letter-spacing: -5px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 72px;
    line-height: 57px;
    letter-spacing: -4px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 59px;
    line-height: 57px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 36px;
    line-height: 37px;
    letter-spacing: -2px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    font-size: 30px;
    line-height: 31px;
  }
`

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 510px;
  margin: 0 auto;
  margin-top: 50px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 383px;
    margin-top: 40px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 353px;
    margin-top: 33px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 247px;
    margin-top: 25px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 223px;
    margin-top: 24px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-top: 27px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    margin-top: 33px;
  }
`

const NFTButton = styled.a`
  position: relative;
  width: 100%;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #6078ff;
  border-radius: 8px;
  padding: 14px 0 17px 0;
  transition: 0.4s;
  text-align: center;
  cursor: pointer;
  margin-bottom: 25px;
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 20px;
    line-height: 25px;
    border-radius: 6px;
    padding: 11px 0 13px 0;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 18px;
    line-height: 23px;
    padding: 10px 0 12px 0;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 7px 0 8px 0;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 5px 0 6px 0;
    margin-bottom: 13px;
  }
  &:hover {
    background: #8698ff;
  }
  span {
    font-weight: 700;
  }
  &:before {
    content: '';
    position: absolute;
    width: 496px;
    height: 1px;
    bottom: -14px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 1px dashed #b6b6b6;
    ${({ theme }) => theme.adaptive.main.lg} {
      width: 373px;
      bottom: -11px;
    }
    ${({ theme }) => theme.adaptive.main.slg} {
      width: 343px;
      bottom: -10px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      width: 240px;
      bottom: -9px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 216px;
      bottom: -8px;
    }
  }
`

const Button = styled.a`
  width: 246px;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: -0.05em;
  color: #000000;
  background: #fff;
  border-radius: 8px;
  padding: 4px 0 7px 0;
  transition: 0.4s;
  text-align: center;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 185px;
    font-size: 14px;
    line-height: 25px;
    padding: 3px 0 5px 0;
    border-radius: 6px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 171px;
    font-size: 13px;
    line-height: 23px;
    padding: 3px 0 4px 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 120px;
    font-size: 13px;
    line-height: 23px;
    padding: 1px 0 3px 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 108px;
    padding: 0 0 1px 0;
  }
  &:first-child {
    margin-left: 0;
  }
  &:hover {
    background: linear-gradient(0deg, #f1f1f1, #f1f1f1), #ffffff;
  }
`

const BannerWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 71px;
  ${({ theme }) => theme.adaptive.main.xl} {
    bottom: 62px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    bottom: 53px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    bottom: 46px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    bottom: 35px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    bottom: 37px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    bottom: 50px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    bottom: 31px;
  }
`
