import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import closeImg from '../../images/forms/close-img.svg'
import successImg from '../../images/forms/success-img.svg'
import { has, clone } from 'lodash'
import { sendEmail } from '../../helpers/sendEmail'
import { SendEmailErrors } from '../../types/mail/mailTypes'
import useOnClickOutside from '../../helpers/hooks/useOnClickOutside'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

interface FormProps {
  open?: boolean
  onClose: () => void
}

interface OpenProps {
  open?: boolean
}

const DEFAULT_ERRORS_STATE: SendEmailErrors = {
  name: false,
  email: false,
  message: false,
}

interface ErrorProps {
  error?: boolean
}

const ContactForm: React.FunctionComponent<FormProps> = (props) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [fieldErrors, setFieldErrors] = useState(DEFAULT_ERRORS_STATE)
  const [errorMessage, setErrorMessage] = useState('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const formRef = useRef(null)

  const error = <Error>{errorMessage}</Error>

  const resetForm = () => {
    setMessage('')
    setName('')
    setEmail('')
    setFieldErrors(DEFAULT_ERRORS_STATE)
    setErrorMessage('')
  }

  const body = document.body

  useEffect(() => {
    props.open ? disableBodyScroll(body) : enableBodyScroll(body)
  }, [props.open])

  const handleSubmit = async () => {
    setIsSending(true)
    const response = await sendEmail({ name, email, message })
    setIsSending(false)
    if (response.error) {
      setErrorMessage(response.error.details[0].message)
      const defaultState = clone(DEFAULT_ERRORS_STATE)
      const errorField: keyof SendEmailErrors =
        response.error.details[0].context.key
      if (has(fieldErrors, errorField)) {
        defaultState[errorField] = true
        setFieldErrors({ ...defaultState })
      }
      return
    }
    setIsSuccess(true)
    setTimeout(() => {
      setIsSuccess(false)
    }, 5000)
    resetForm()
  }

  useOnClickOutside({
    ref: formRef,
    handler: () => props.onClose(),
  })

  return (
    <Wrapper open={props.open}>
      <Form ref={formRef} open={props.open}>
        <Container>
          <Content>
            <Title>Contact us</Title>
            <Field error={fieldErrors.name}>
              <TopLine>
                <Label>Name</Label>
                {error}
              </TopLine>
              <Input
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Field>
            <Field error={fieldErrors.email}>
              <TopLine>
                <Label>email address</Label>
                {error}
              </TopLine>
              <Input
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Field>
            <Field error={fieldErrors.message}>
              <TopLine>
                <Label>message</Label>
                {error}
              </TopLine>
              <TextArea
                value={message}
                onChange={(event) => setMessage(event.target.value)}
              />
            </Field>
          </Content>
          <Send onClick={handleSubmit} disabled={isSending}>
            SEND MESSAGE
          </Send>
        </Container>
        <Close onClick={() => props.onClose()} />
        <SuccessBlock open={isSuccess}>
          <Container>
            <Content>
              <SuccessImg />
              <SuccessTitle>Your message was sent.</SuccessTitle>
              <SuccessText>
                Thank you for contacting us. We will get back to you as soon as
                possible.
              </SuccessText>
            </Content>
            <SuccessBtn onClick={() => setIsSuccess(false)}>Done</SuccessBtn>
          </Container>
        </SuccessBlock>
      </Form>
    </Wrapper>
  )
}

export default ContactForm

const Wrapper = styled.div<OpenProps>`
  position: fixed;
  opacity: ${({ open }) => (open ? '1' : '0')};
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  transition: 0.4s;
  z-index: ${({ open }) => (open ? '6' : ' -1')};
  ${({ theme }) => theme.adaptive.form.sm} {
    height: auto;
  }
`

const Form = styled.div<OpenProps>`
  position: absolute;
  top: 0;
  left: ${({ open }) => (open ? css`calc(100% - 480px)` : '100%')};
  background: #fdfdfd;
  width: 480px;
  height: 100vh;
  padding: 64px 43px;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.form.sm} {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 82px 24px 56px;
    overflow: scroll;
    left: ${({ open }) => (open ? '0' : '100%')};
  }
  ${({ theme }) => theme.adaptive.form.xsm} {
    padding: 82px 22px 56px;
  }
`

const Container = styled.div`
  width: 100%;
  ${({ theme }) => theme.adaptive.form.sm} {
    max-width: 576px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  ${({ theme }) => theme.adaptive.form.xsm} {
    width: 100%;
  }
`

const Content = styled.div``

const Title = styled.div`
  font-size: 50px;
  line-height: 48px;
  letter-spacing: -2.2px;
  color: #000000;
  margin-bottom: 48px;
  ${({ theme }) => theme.adaptive.form.sm} {
    font-size: 50px;
    line-height: 48px;
    letter-spacing: -2.2px;
    margin-bottom: 40px;
  }
  ${({ theme }) => theme.adaptive.form.xsm} {
    font-size: 42px;
    line-height: 46px;
    letter-spacing: -1px;
  }
`

const Label = styled.div`
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #000000;
`

const Error = styled(Label)`
  color: #ff2e2e;
  ${({ theme }) => theme.adaptive.form.sm} {
    font-size: 9px;
  }
  ${({ theme }) => theme.adaptive.form.xsm} {
    font-size: 7px;
  }
`

const Field = styled.div<ErrorProps>`
  margin-bottom: 32px;
  ${({ theme }) => theme.adaptive.form.sm} {
    margin-bottom: 24px;
  }
  ${Error} {
    opacity: ${({ error }) => (error ? '1' : 0)};
  }
`

const TopLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const InputCss = css`
  width: 100%;
  padding: 13px 24px;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.7;
  border: 0;
  outline: none;
  background: #e4e3e5;
  ${({ theme }) => theme.adaptive.form.sm} {
    padding: 14px 20px;
  }
  ${({ theme }) => theme.adaptive.form.xsm} {
    padding: 13px 16px;
  }
`

const Input = styled.input`
  ${InputCss};
  height: 55px;
`

const TextArea = styled.textarea`
  ${InputCss};
  height: 199px;
  resize: none;
  ${({ theme }) => theme.adaptive.form.sm} {
    height: 110px;
  }
  ${({ theme }) => theme.adaptive.form.xsm} {
    height: 103px;
  }
`

const BtnCss = css`
  width: 178px;
  height: 55px;
  border-radius: 82px;
  padding: 19px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 1.5px;
  cursor: pointer;
`

const Send = styled.button`
  ${BtnCss};
  background: #6078ff;
  color: #ffffff;
  margin-top: 32px;
  cursor: pointer;
  outline: none;
  border: 0;
  ${({ theme }) => theme.adaptive.form.sm} {
    width: 100%;
    margin-top: 0;
  }
  &:disabled {
    background: #d1d3dc;
  }
`

const Close = styled.div`
  position: absolute;
  top: 24px;
  right: 30px;
  width: 32px;
  height: 32px;
  background: url('${closeImg}') center no-repeat;
  background-size: cover;
  cursor: pointer;
  z-index: 10;
  ${({ theme }) => theme.adaptive.form.sm} {
    top: 18px;
    right: 24px;
  }
  ${({ theme }) => theme.adaptive.form.xsm} {
    top: 18px;
    right: 22px;
  }
`

const SuccessBlock = styled.div<OpenProps>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fdfdfd;
  padding: 160px 40px;
  ${({ theme }) => theme.adaptive.form.sm} {
    min-height: 100%;
    height: auto;
    padding: 200px 24px;
  }
  ${({ theme }) => theme.adaptive.form.xsm} {
    padding: 168px 22px;
  }
`

const SuccessImg = styled.div`
  width: 96px;
  height: 96px;
  margin-bottom: 16px;
  background: url('${successImg}') center no-repeat;
  ${({ theme }) => theme.adaptive.form.xsm} {
    width: 72px;
    height: 72px;
    margin-bottom: 12px;
    background-size: cover;
  }
`

const SuccessTitle = styled.div`
  font-size: 50px;
  line-height: 48px;
  letter-spacing: -2.2px;
  color: #000000;
  margin-bottom: 24px;
  max-width: 302px;
  ${({ theme }) => theme.adaptive.form.xsm} {
    font-size: 42px;
    line-height: 42px;
    letter-spacing: -2px;
    max-width: 292px;
    margin-bottom: 16px;
  }
`

const SuccessText = styled.div`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.3px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.6;
  margin-bottom: 32px;
  max-width: 289px;
  ${({ theme }) => theme.adaptive.form.xsm} {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.3px;
    max-width: 290px;
  }
`

const SuccessBtn = styled.div`
  ${BtnCss};
  background: #000;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.form.xsm} {
    width: 100%;
  }
`
