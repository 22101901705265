import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Container, TitleH2 } from 'theme/globalStyle'
import getLatestMediumPosts from 'api/medium'
import { ReactComponent as MoreLinkArrow } from 'images/index/news/arrowMore.svg'
import { MEDIUM_LINK } from 'configs/app'
import { MoreLink, TitleWrapper, MobileMoreLink } from './components'

function Medium() {
  const [postsList, setPostsList] = useState<object[]>([])
  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await getLatestMediumPosts()
      setPostsList(posts.data.slice(0, 3))
      // setPostFetched(true)
    }
    fetchPosts()
  }, [])

  if (!postsList.length) return null

  return (
    <Root>
      <TitleWrapper>
        <Container>
          <TitleH2>
            <span>Read</span> about HStarter
          </TitleH2>
          <MoreLink href={MEDIUM_LINK} target="_blank">
            All HStarter News <MoreLinkArrow />
          </MoreLink>
        </Container>
      </TitleWrapper>
      <Container>
        <NewsList>
          {postsList.map((post: any) => {
            return (
              <New href={post.link} target="_blank">
                <Description>{post.title}</Description>
                <Image style={{ backgroundImage: `url(${post.thumbnail}` }} />
              </New>
            )
          })}
        </NewsList>
        <MobileMoreLink>
          <MoreLink href={MEDIUM_LINK} target="_blank">
            All HStarter News <MoreLinkArrow />
          </MoreLink>
        </MobileMoreLink>
      </Container>
    </Root>
  )
}

export default Medium

const Root = styled.div`
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 50px 0 101px 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 47px 0 63px 0;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding: 42px 0 43px 0;
  }
  ${TitleH2} {
    span {
      ${({ theme }) => theme.adaptive.main.sm} {
        display: block;
      }
    }
  }
  ${TitleWrapper} {
    ${MoreLink} {
      ${({ theme }) => theme.adaptive.main.smd} {
        display: none;
      }
    }
  }
`

const NewsList = styled.div`
  display: flex;
  gap: 143px;
  padding: 80px 0 131px 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 62px 0 115px 0;
    gap: 121px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 32px 0 99px 0;
    gap: 107px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 27px 0 85px 0;
    gap: 95px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 19px 0 64px 0;
    gap: 78px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 48px 0 0 0;
    display: block;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 42px 0 0 0;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding: 42px 0 0 0;
  }
`

const Image = styled.div`
  width: 100%;
  height: 220px;
  background: #e4e3e5;
  border: 1px solid #000000;
  border-radius: 11px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0);
  transition: box-shadow 0.3s ease;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 200px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 170px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 145px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    border-radius: 6px;
    height: 125px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    border-radius: 10px;
    height: 264px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 140px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    height: 118px;
  }
`

const New = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 33.333333%;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 100%;
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-bottom: 29px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    margin-bottom: 27px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    ${Image} {
      box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.25);
    }
  }
`

const Description = styled.p`
  margin-bottom: 22px;
  font-weight: 400;
  font-size: 20px;
  line-height: 114%;
  letter-spacing: -0.03em;
  color: #000000;
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 18px;
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 16px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 13px;
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 16px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-bottom: 20px;
  }
`
