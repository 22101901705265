const tableData = [
  {
    name: 'Conception & Initiation',
    noTitle: true,
    rows: [
      <>
        <ul>
          <li>Scoping</li>
          <li>Plan Review</li>
          <li>Initiation</li>
          <li>Frontend Design Begin</li>
        </ul>
      </>,
    ],
  },
  {
    name: 'Development Ramp-Up and Community Seeding',
    rows: [
      <>
        <ul>
          <li>Frontend Design </li>
          <li>Frontend Backend Integration</li>
        </ul>
      </>,
      <>
        <ul>
          <li>Influencer Relationship</li>
          <li>Marketing Campaign</li>
        </ul>
      </>,
      <>
        <ul>
          <li>Community Development Initiates</li>
          <li>Content Creation Initiates</li>
          <li>Community AMA’s</li>
        </ul>
      </>,
    ],
  },
  {
    name: 'Development Peaking and Marketing Solidification',
    rows: [
      <>
        <ul>
          <li>Mirror Node </li>
          <li>Finalize Backend Development</li>
          <li>Internal Testing</li>
          <li>Audit Begins</li>
          <li>Audit Completed & Finalized</li>
          <li>Finalize Frontend Development</li>
        </ul>
      </>,
      <>
        <ul>
          <li>Investors Announced</li>
          <li>Incubator Applications Open</li>
          <li>Private-Beta Begins</li>
          <li>Whitelisted-Beta begins </li>
        </ul>
      </>,
      <>
        <ul>
          <li>Formation of HStarter Influencer Network</li>
          <li>Full Content Creation Ramp up</li>
          <li>Hedera NFT Outreach</li>
        </ul>
      </>,
    ],
  },
  {
    name: 'Deployment and Community Cultivation',
    rows: [
      <>
        <ul>
          <li>TGE on BSC</li>
        </ul>
      </>,
      <>
        <ul>
          <li>Solidification of Content Creation</li>
        </ul>
      </>,
      <>
        <ul>
          <li>Deployment of DAO Forums</li>
        </ul>
      </>,
    ],
  },
  {
    name: 'Final Deployment on Hedera Mainnet:',
    rows: [
      <>
        <ul>
          <li>
            Integration with Hedera Mainnet and Hedera Smart Contract Service
          </li>
        </ul>
      </>,
      <>
        <ul>
          <li>Continued Marketing Push Community</li>
        </ul>
      </>,
      <>
        <ul>
          <li>Continued Community Growth Push</li>
        </ul>
      </>,
    ],
  },
]

export const slotNames = ['TECHNOLOGY', 'MARKETING', 'COMMUNITY']

export const rows = 3

export default tableData
