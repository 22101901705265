import React from 'react'
import useWindowSize from '../../helpers/utils/useWindowSize'

interface FormProps {
  open?: boolean
}

const Logo: React.FunctionComponent<FormProps> = (props) => {
  const { width } = useWindowSize()

  const Text = (
    <>
      <path
        d="M55.8232 22.4919V9.15012H53.191V14.6355H46.7211V9.15012H44.0889V22.4919H46.7211V16.9864H53.191V22.4919H55.8232Z"
        fill=""
      />
      <path
        d="M58.0717 19.1619C58.6745 20.8899 60.3422 22.7384 63.8585 22.7384C67.3547 22.7384 69.1429 20.709 69.1429 18.7198C69.1429 16.7306 67.6962 15.3442 65.2248 15.083L62.7132 14.8218C61.1258 14.661 60.2417 14.0181 60.2417 12.7924C60.2417 11.5868 61.4272 10.5621 63.5169 10.5621C65.265 10.5621 66.6916 11.3256 67.2341 12.4508H69.2032C68.6004 10.8233 66.8724 8.87424 63.5772 8.87424C60.4226 8.87424 58.4334 10.5219 58.4334 12.8728C58.4334 14.7414 59.5184 16.2082 62.1707 16.4694L64.5014 16.7105C66.4304 16.9315 67.3346 17.5343 67.3346 18.8203C67.3346 20.1062 65.8678 21.0506 63.8585 21.0506C62.0903 21.0506 60.6637 20.3072 60.0408 19.1619H58.0717Z"
        fill=""
      />
      <path
        d="M71.8751 10.7027V13.2344H70.1671V14.7615H71.8751V19.5035C71.8751 21.5128 73.1208 22.4772 75.1301 22.4772H76.5567V20.9502H75.2909C74.0853 20.9502 73.6031 20.3474 73.6031 19.1418V14.7615H76.5567V13.2344H73.6031V10.7027H71.8751Z"
        fill=""
      />
      <path
        d="M82.4097 12.9732C80.2999 12.9732 78.4313 14.0181 77.9089 15.9269H79.8177C80.1995 15.0428 81.2845 14.5807 82.4097 14.5807C83.9368 14.5807 85.1022 15.1433 85.1022 16.6703H82.1887C80.0387 16.6703 78.1299 17.4138 78.1299 19.6642C78.1299 21.7338 79.7173 22.7384 81.8873 22.7384C83.334 22.7384 84.3788 22.2562 85.1022 21.4726V22.4772H86.8302V16.59C86.8302 14.2793 85.0419 12.9732 82.4097 12.9732ZM85.1022 18.7801C85.1022 20.1665 83.9368 21.131 82.0681 21.131C80.7219 21.131 79.8579 20.6889 79.8579 19.6642C79.8579 18.6395 80.7219 18.1773 82.1686 18.1773H85.1022V18.7801Z"
        fill=""
      />
      <path
        d="M89.4911 13.2344V22.4772H91.219V18.0367C91.219 16.1077 92.4447 14.8017 94.4741 14.8017H95.0166V13.1541H94.4741C92.7863 13.1541 91.7214 13.8171 91.219 14.5405V13.2344H89.4911Z"
        fill=""
      />
      <path
        d="M98.2256 10.7027V13.2344H96.5177V14.7615H98.2256V19.5035C98.2256 21.5128 99.4714 22.4772 101.481 22.4772H102.907V20.9502H101.641C100.436 20.9502 99.9536 20.3474 99.9536 19.1418V14.7615H102.907V13.2344H99.9536V10.7027H98.2256Z"
        fill=""
      />
      <path
        d="M109.048 21.131C107.44 21.131 106.215 20.0058 105.994 18.4988H113.448C113.508 18.2376 113.488 17.9563 113.488 17.7353C113.488 15.083 111.579 12.9732 108.947 12.9732C106.154 12.9732 104.205 15.0629 104.205 17.8357C104.205 20.6889 106.255 22.7384 109.048 22.7384C111.077 22.7384 112.725 21.6735 113.368 19.8852H111.499C111.017 20.709 110.153 21.131 109.048 21.131ZM108.947 14.5807C110.474 14.5807 111.459 15.5451 111.68 16.9918H106.014C106.235 15.5451 107.34 14.5807 108.947 14.5807Z"
        fill=""
      />
      <path
        d="M115.744 13.2344V22.4772H117.472V18.0367C117.472 16.1077 118.697 14.8017 120.727 14.8017H121.269V13.1541H120.727C119.039 13.1541 117.974 13.8171 117.472 14.5405V13.2344H115.744Z"
        fill=""
      />
    </>
  )

  return (
    <svg
      width="176"
      height="32"
      viewBox="0 0 176 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM16.2314 13.9741C19.067 13.9741 21.3734 11.7086 21.4393 8.88889H22.9953V22.2842H21.4407L21.4407 22.276C21.4407 19.3989 19.1085 17.0667 16.2314 17.0667C13.3544 17.0667 11.0221 19.3989 11.0221 22.276L11.0221 22.2842H9.6V8.88889H11.0236C11.0895 11.7086 13.3959 13.9741 16.2314 13.9741Z"
        fill=""
      />
      {Text}
    </svg>
  )
}

export default Logo
