import React from 'react'
import styled from 'styled-components'
import { Container } from 'theme/globalStyle'
import { ReactComponent as TextImage } from 'images/index/about/text.svg'
import wordsBg from 'images/index/about/text-bg.svg'
import earthImage from 'images/index/about/earth.png'

function About() {
  return (
    <Root>
      <Top>
        <Container>
          <Text>
            <Title>
              <TextImage />
            </Title>
            <SubTitle>the archetypes of launchpads.</SubTitle>
          </Text>
        </Container>
        <Image />
      </Top>
      <Bottom>
        <Container />
        <Description>
          <DescriptionText>
            Hedera is a unique DLT, and therefore a typical dApp that simply
            launches tokens onto a standard smart contract platform simply will
            not do.
          </DescriptionText>
        </Description>
      </Bottom>
      <WordsBlock>
        <Container>
          <WordsText>
            HStarter has been designed specifically to{' '}
            <b>
              break the mould in terms of both pure technical features, as well
              as the mindset guiding the team
            </b>{' '}
            which will form the culture of the coming Hedera Hashgraph
            ecosystem.
          </WordsText>
        </Container>
      </WordsBlock>
    </Root>
  )
}

export default About

const Root = styled.div`
  border-bottom: 1px dashed #b6b6b6;
  margin-bottom: 130px;
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-bottom: 86px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-bottom: 54px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-bottom: 51px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-bottom: 66px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    margin-bottom: 69px;
  }
`

const WordsBlock = styled.div`
  position: relative;
  display: flex;
  height: 343px;
  border-top: 1px dashed #b6b6b6;
  padding-left: 37px;
  padding-top: 61px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 322px;
    padding-top: 52px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 312px;
    padding-top: 50px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding-left: 44px;
    height: 223px;
    padding-top: 32px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-left: 43px;
    height: 180px;
    padding-top: 28px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding-left: 36px;
    height: 196px;
    padding-top: 50px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding-left: 0;
    height: 194px;
    padding-top: 21px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    height: 168px;
    padding-top: 24px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(${wordsBg}) repeat;
    background-position: center;
    background-size: 990px;
  }
`

const WordsText = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 45px;
  line-height: 130%;
  letter-spacing: -0.04em;
  color: #000000;
  background: #e4e3e5;
  padding-left: 2px;
  padding-bottom: 8px;
  max-width: 1121px;
  z-index: 2;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 41px;
    max-width: 1047px;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 30px;
    max-width: 750px;
    margin: 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 23px;
    max-width: 588px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 19px;
    max-width: 490px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    max-width: 100%;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    font-size: 16px;
  }
  b {
    color: #647bf7;
  }
`

const Top = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 60%;
    height: 100%;
    background: #dfdde0;
    ${({ theme }) => theme.adaptive.main.smd} {
      width: 100%;
      height: 258px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      height: 261px;
    }
    ${({ theme }) => theme.adaptive.main.xsm} {
      height: 222px;
    }
  }
`

const Bottom = styled.div`
  position: relative;
  ${Container} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    border-right: 1px dashed #b6b6b6;
    height: 100%;
    ${({ theme }) => theme.adaptive.main.smd} {
      border-right: 0;
    }
  }
`

const Image = styled.div`
  position: absolute;
  width: 40%;
  height: 100%;
  right: 0;
  top: 0;
  border-top: 1px dashed #b6b6b6;
  border-bottom: 1px dashed #b6b6b6;
  background: url(${earthImage}) no-repeat center;
  background-size: 85%;
  ${({ theme }) => theme.adaptive.main.smd} {
    position: relative;
    width: 100%;
    height: 327px;
    background-size: 500px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 237px;
    background-size: 300px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    height: 228px;
  }
`

const Description = styled.div`
  position: relative;
  width: 40%;
  border-left: 1px dashed #b6b6b6;
  margin-left: auto;
  padding: 52px 46px 52px 65px;
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 39px 29px 37px 44px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 33px 16px 30px 39px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 19px 13px 18px 24px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 560px;
    padding: 39px 54px 20px 16px;
    border-left: 0;
    margin: 0 auto;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 340px;
    padding: 25px 0 32px 19px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    width: 280px;
    padding: 26px 0 47px 9px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 10px;
    top: 10px;
    width: 19px;
    height: 19px;
    background: #647bf7;
    ${({ theme }) => theme.adaptive.main.lg} {
      top: 7px;
      left: 7px;
      width: 14px;
      height: 14px;
    }
    ${({ theme }) => theme.adaptive.main.slg} {
      top: 6px;
      left: 6px;
      width: 12px;
      height: 12px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      top: 5px;
      left: 5px;
      width: 9px;
      height: 9px;
    }
    ${({ theme }) => theme.adaptive.main.smd} {
      top: 26px;
      left: 0;
      width: 8px;
      height: 8px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      top: 10px;
    }
  }
`

const DescriptionText = styled.p`
  margin: 0;
  font-size: 20px;
  line-height: 114%;
  letter-spacing: -0.03em;
  color: #727172;
  max-width: 390px;
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 16px;
    max-width: 355px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 14px;
    max-width: 314px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 13px;
    max-width: 224px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    max-width: unset;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 14px;
  }
`

const Text = styled.div`
  position: relative;
  z-index: 1;
  padding: 116px 0 84px 0;
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 87px 0 61px 0;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 75px 0 54px 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 49px 0 37px 0;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding: 48px 0 37px 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 56px 0 57px 0;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding: 49px 0 45px 0;
  }
`
const Title = styled.div`
  margin-bottom: 56px;
  width: 55%;
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-bottom: 26px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 370px;
    margin-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 100%;
    margin-bottom: 15px;
  }
  svg {
    width: 100%;
    height: auto;
  }
`
const SubTitle = styled.p`
  color: #b2b1b3;
  font-style: italic;
  font-size: 48px;
  line-height: 130%;
  font-weight: 300;
  letter-spacing: -0.04em;
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 40px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 34px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 28px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 20px;
  }
`
