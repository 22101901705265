import React, { useState } from 'react'
import styled from 'styled-components'

function Banner() {
  const [isHidden, setIsHidden] = useState(false)

  if (isHidden) return null

  return (
    <Root>
      <Text>
        Always make sure the URL is <br /> <b>www.hstarter.com </b>
      </Text>
      <Bookmark>— bookmark it to be safe.</Bookmark>
      <Close type="button" onClick={() => setIsHidden(true)} />
    </Root>
  )
}

export default Banner

const Root = styled.div`
  display: inline-block;
  background: #6078ff;
  border-radius: 8px;
  padding: 9px 16px 16px 16px;
  position: relative;
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 6px 7px 11px 12px;
  }
`

const Text = styled.p`
  color: #ffffff;
  font-weight: 300;
  font-size: 20px;
  line-height: 114%;
  letter-spacing: -0.03em;
  margin-bottom: 10px;
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 15px;
    margin-bottom: 7px;
  }
`

const Bookmark = styled.p`
  font-size: 14px;
  line-height: 114%;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.5);
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 12px;
  }
`

const Close = styled.button`
  position: absolute;
  bottom: 16px;
  right: 22px;
  width: 18px;
  height: 18px;
  ${({ theme }) => theme.adaptive.main.md} {
    bottom: 11px;
    right: 16px;
    width: 14px;
    height: 14px;
  }
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 120%;
    height: 2px;
    background: #e4e3e5;
    top: 50%;
    left: 0;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`
