import React from 'react'
import styled, { css } from 'styled-components'
import { Container } from 'theme/globalStyle'
import Form from './Form'
import Socials from './Socials'
import { ReactComponent as Logo } from 'images/footer/logo.svg'

const Footer: React.FunctionComponent = () => {
  return (
    <Root>
      <Container>
        <TableWrapper>
          <TableHead>
            <HeadText>Never want to miss a project?</HeadText>
          </TableHead>
          <SignUpRow>
            <SignUpLeft>
              <SignUpTitle>
                Sign up to the <span>H</span>Starter Newsletter!
              </SignUpTitle>
            </SignUpLeft>
            <SubscribeRight>
              <Form />
            </SubscribeRight>
          </SignUpRow>
          <BottomRow>
            <BottomLeft>
              <CopyRightRow>
                <LogoWrap>
                  <Logo />
                </LogoWrap>
                <CopyRight>© HStarter.fi, 2024</CopyRight>
              </CopyRightRow>
            </BottomLeft>
            <SocialsWrap>
              <SocialsRow>
                {/* <Terms>Terms</Terms> */}
                {/* <Privacy>Privacy</Privacy> */}
                <Socials />
              </SocialsRow>
            </SocialsWrap>
          </BottomRow>
        </TableWrapper>
      </Container>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  position: relative;
  padding-top: 80px;
  padding-bottom: 46px;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding-top: 36px;
    padding-bottom: 43px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding-top: 50px;
    padding-bottom: 33px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding-top: 43px;
    padding-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-top: 32px;
    padding-bottom: 39px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding-top: 35px;
    padding-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding-top: 55px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding-top: 44px;
  }
`

const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    left: 0;
    top: 0;
    border-left: 1px dashed #b6b6b6;
    ${({ theme }) => theme.adaptive.main.smd} {
      content: unset;
    }
  }
`

const BorderCss = css`
  position: absolute;
  width: 100vw;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  border-top: 1px dashed #b6b6b6;
`

const TableHead = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 28px;
  width: 100%;
  height: 62px;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding-left: 19px;
    height: 68px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding-left: 21px;
    height: 56px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 48px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-left: 20px;
    height: 43px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding-left: 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 45px;
  }
  &:before {
    content: '';
    ${BorderCss};
  }
`

const HeadText = styled.div`
  font-weight: 300;
  font-size: 20px;
  letter-spacing: -0.03em;
  color: #000000;
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 20px;
  }
`

const LeftSideCss = css`
  display: flex;
  min-width: 1024px;
  padding-left: 28px;
  ${({ theme }) => theme.adaptive.main.xl} {
    min-width: 915px;
    padding-left: 19px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    min-width: 740px;
    padding-left: 21px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    min-width: 620px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    min-width: 489px;
    padding-left: 20px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    min-width: 100%;
    padding-left: 0;
  }
`

const SignUpRow = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 75px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 66px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 56px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 48px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 42px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    display: block;
    height: auto;
  }
  &:before {
    content: '';
    ${BorderCss};
  }
`

const SignUpLeft = styled.div`
  position: relative;
  align-items: center;
  ${LeftSideCss};
  ${({ theme }) => theme.adaptive.main.smd} {
    height: 52px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 74px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 400px;
    border-right: 1px dashed #b6b6b6;
    ${({ theme }) => theme.adaptive.main.smd} {
      top: unset;
      width: 100vw;
      height: 1px;
      border-top: 1px dashed #b6b6b6;
      bottom: 0;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const SignUpTitle = styled.div`
  font-weight: 300;
  font-size: 50px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #000000;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 48px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 38px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 33px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 25px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    font-size: 26px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    font-size: 22px;
  }
  span {
    font-weight: 700;
  }
`

const BottomRow = styled.div`
  position: relative;
  display: flex;
  height: 188px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 165px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 141px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 122px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 104px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    display: block;
    height: auto;
  }
  &:before {
    content: '';
    ${BorderCss};
  }
  &:after {
    content: '';
    ${BorderCss};
    top: unset;
    bottom: 0;
    ${({ theme }) => theme.adaptive.main.smd} {
      content: unset;
    }
  }
`

const BottomLeft = styled.div`
  position: relative;
  align-items: flex-end;
  padding-bottom: 27px;
  ${LeftSideCss};
  ${({ theme }) => theme.adaptive.main.xl} {
    padding-bottom: 24px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-bottom: 13px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    padding-bottom: 0;
    align-items: center;
    height: 73px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 100px;
  }
  &:before {
    ${({ theme }) => theme.adaptive.main.smd} {
      content: '';
      position: absolute;
      width: 100vw;
      height: 1px;
      border-top: 1px dashed #b6b6b6;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      ${({ theme }) => theme.adaptive.main.sm} {
        content: unset;
      }
    }
  }
`

const CopyRightRow = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) => theme.adaptive.main.smd} {
    align-items: center;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    display: block;
  }
`

const LogoWrap = styled.div`
  cursor: pointer;
  svg {
    display: block;
    width: auto;
    height: 43px;
    transition: 0.4s;
    fill: #000;
    ${({ theme }) => theme.adaptive.main.xl} {
      height: 38px;
    }
    ${({ theme }) => theme.adaptive.main.slg} {
      height: 33px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      height: 28px;
    }
    ${({ theme }) => theme.adaptive.main.sm} {
      height: 33px;
      margin-bottom: 16px;
    }
  }
  &:hover {
    svg {
      fill: #6078ff;
    }
  }
`

const CopyRight = styled.div`
  font-size: 14px;
  text-align: right;
  letter-spacing: -0.03em;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.3;
  padding-bottom: 6px;
  margin-right: 176px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-right: 169px;
    padding-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-right: 76px;
    padding-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-right: 26px;
    padding-bottom: 4px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-right: 9px;
    padding-bottom: 2px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-right: 0;
    padding-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    text-align: left;
  }
`

const RightSide = css`
  display: flex;
  align-items: center;
  width: 100%;
`

const SubscribeRight = styled.div`
  position: relative;
  ${RightSide};
  padding-left: 29px;
  background: #dfdde0;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding-left: 19px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding-left: 21px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding-left: 16px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-left: 18px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 100%;
    height: 40px;
    padding-left: 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    height: 47px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 400px;
    border-right: 1px dashed #b6b6b6;
    ${({ theme }) => theme.adaptive.main.smd} {
      border: 0;
      width: 100vw;
      height: 100%;
      background: #dfdde0;
      right: unset;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

const SocialsWrap = styled.div`
  ${RightSide};
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0 21px 27px 50px;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 0 13px 26px 19px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 0 12px 25px 18px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding: 0 12px 19px 16px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 0 11px 15px 18px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    justify-content: flex-end;
    padding: 15px 0 32px 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 22px 0 20px 0;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    padding: 29px 0 21px 0;
  }
`

const SocialsRow = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 100%;
    justify-content: space-between;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    display: block;
  }
`

const LinkCss = css`
  font-weight: 400;
  font-size: 12px;
  line-height: 11px;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.4;
  cursor: pointer;
  transition: 0.4s;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 11px;
    line-height: 9px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 12px;
    line-height: 10px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    line-height: 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    line-height: 7px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    line-height: 8px;
  }
  &:hover {
    color: #6078ff;
    opacity: 1;
  }
`

// const Terms = styled.a`
//   ${LinkCss};
//   margin-right: 30px;
//   ${({ theme }) => theme.adaptive.main.xl} {
//     margin-right: 22px;
//   }
//   ${({ theme }) => theme.adaptive.main.lg} {
//     margin-right: 18px;
//   }
//   ${({ theme }) => theme.adaptive.main.slg} {
//     margin-right: 13px;
//   }
//   ${({ theme }) => theme.adaptive.main.md} {
//     margin-right: 15px;
//   }
//   ${({ theme }) => theme.adaptive.main.xsm} {
//     margin-right: 13px;
//   }
// `

// const Privacy = styled.a`
//   ${LinkCss};
//   margin-right: 35px;
//   ${({ theme }) => theme.adaptive.main.xl} {
//     margin-right: 21px;
//   }
//   ${({ theme }) => theme.adaptive.main.lg} {
//     margin-right: 18px;
//   }
//   ${({ theme }) => theme.adaptive.main.slg} {
//     margin-right: 14px;
//   }
//   ${({ theme }) => theme.adaptive.main.md} {
//     margin-right: 15px;
//   }
//   ${({ theme }) => theme.adaptive.main.sm} {
//     margin-right: 24px;
//   }
//   ${({ theme }) => theme.adaptive.main.xsm} {
//     margin-right: 0;
//   }
// `
