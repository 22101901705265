import React from 'react'
import styled, { css } from 'styled-components'
import { Container, TitleH2 } from 'theme/globalStyle'
import tableData, { rows, slotNames } from './data'
import { breakpoints } from 'theme/theme'
import useWindowSize from 'helpers/utils/useWindowSize'
import boxesImage from 'images/index/roadmap/boxes.jpg'
import engineImage from 'images/index/roadmap/engine.jpg'

enum TableLinesType {
  Large,
  Small,
}

function Roadmap() {
  const { width } = useWindowSize()

  const DesktopTable = (
    <TableWrapper>
      <TableHeadRow>
        <TableHeadLines>
          <TableLine type={TableLinesType.Large} />
          <TableLine type={TableLinesType.Small} />
          <TableLine type={TableLinesType.Small} />
        </TableHeadLines>
        <Container />
        <TableHeadLines>
          <TableLine type={TableLinesType.Large} />
          <TableLine type={TableLinesType.Small} />
          <TableLine type={TableLinesType.Small} />
        </TableHeadLines>
      </TableHeadRow>
      <Container>
        <BoxesImage src={boxesImage} />
        <EngineImage src={engineImage} />
        <Table>
          <thead>
            <tr>
              {tableData.map((data, idx) => {
                return (
                  <th>
                    <StageTitle>stage {idx + 1}</StageTitle>
                    <StageDesc>{data.name}</StageDesc>
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(rows), (e, i) => {
              return (
                <tr>
                  {tableData.map((data, idx) => {
                    return (
                      <td>
                        {!data.noTitle ? <TdTitle>{slotNames[i]}</TdTitle> : ''}
                        {data.rows[i]}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Container>
    </TableWrapper>
  )

  const TabletTable = (
    <RowsRoadmap>
      {tableData.map((data, idx) => {
        return (
          <div>
            <RowsHeader>
              <Container>
                <StageTitle>stage {idx + 1}</StageTitle>
                <StageDesc>{data.name}</StageDesc>
              </Container>
            </RowsHeader>
            <Container>
              <RowsContent>
                {data.rows.map((content, i) => {
                  return (
                    <>
                      {!data.noTitle && slotNames[i] ? (
                        <TdTitle>{slotNames[i]}</TdTitle>
                      ) : null}
                      {content}
                    </>
                  )
                })}
              </RowsContent>
            </Container>
          </div>
        )
      })}
    </RowsRoadmap>
  )

  return (
    <Root>
      <Container>
        <RowTitle>
          <TitleH2>
            <span>Road</span>map
          </TitleH2>
          <Description>
            Timelines Are Constantly Being Updated <br /> *stages are
            approximate and subject to change as there are many factors that are
            beyond our control
          </Description>
        </RowTitle>
      </Container>
      {width <= breakpoints.smd ? TabletTable : DesktopTable}
    </Root>
  )
}

export default Roadmap

const RowsRoadmap = styled.div``

const Root = styled.div`
  position: relative;
  z-index: 5;
  padding-top: 120px;
  border-bottom: 1px dashed #b6b6b6;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding-top: 105px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding-top: 67px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    padding-top: 78px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding-top: 64px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    ${Container} {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  ${RowsRoadmap} > div:first-child {
    li {
      &:before {
        background: #6078ff;
      }
    }
  }
`

const RowTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 56px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-bottom: 46px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-bottom: 40px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-bottom: 33px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    display: block;
    margin-bottom: 17px;
  }
`

const Description = styled.p`
  position: relative;
  top: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #727172;
  margin-left: 56px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-left: 49px;
    top: 9px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 13px;
    margin-left: 31px;
    top: 4px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 13px;
    margin-left: 20px;
    top: 2px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 11px;
    margin-left: 22px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-left: 0;
    font-size: 13px;
    top: 0;
    margin-top: 15px;
    max-width: 524px;
  }
`

const TableWrapper = styled.div`
  position: relative;
  ${Container} {
    position: relative;
  }
`

const roadmapList = css`
  ul {
    padding: 0;
    list-style: none;
    li {
      position: relative;
      padding-left: 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: -0.03em;
      color: #727172;
      margin-bottom: 9px;
      ${({ theme }) => theme.adaptive.main.xl} {
        margin-bottom: 7px;
      }
      ${({ theme }) => theme.adaptive.main.lg} {
        font-size: 13px;
        margin-bottom: 3px;
        padding-left: 12px;
      }
      ${({ theme }) => theme.adaptive.main.slg} {
        margin-bottom: 2px;
        padding-left: 11px;
      }
      ${({ theme }) => theme.adaptive.main.md} {
        padding-left: 8px;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        width: 7px;
        height: 7px;
        background: #000000;
        border-radius: 50%;
        background: #727172;
        ${({ theme }) => theme.adaptive.main.xl} {
          width: 6px;
          height: 6px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          width: 5px;
          height: 5px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          width: 4px;
          height: 4px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const Table = styled.table`
  position: relative;
  z-index: 2;
  width: 100%;
  border-collapse: collapse;
  tr {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100vw;
      height: 2px;
      border-bottom: 1px dashed #b6b6b6;
      bottom: 0px;
      left: 0;
    }
  }
  tbody tr:first-child td:first-child {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #e4e3e5;
    }
    ul li {
      &:before {
        background: #6078ff;
      }
    }
  }
  tbody tr {
    &:last-child {
      &:before {
        opacity: 0;
      }
    }
  }
  th {
    text-align: left;
    background: #647bf7;
    width: 20%;
    padding: 16px 0 0 30px;
    height: 122px;
    ${({ theme }) => theme.adaptive.main.xl} {
      height: 107px;
      padding: 13px 19px 0 19px;
    }
    ${({ theme }) => theme.adaptive.main.lg} {
      height: 92px;
      padding: 11px 19px 0 19px;
    }
    ${({ theme }) => theme.adaptive.main.slg} {
      height: 79px;
      padding: 9px 14px 0 18px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      height: 72px;
      padding: 9px 10px 0 15px;
    }
  }
  th,
  td {
    vertical-align: top;
    border-left: 1px dashed #b6b6b6;
    &:last-child {
      border-right: 1px dashed #b6b6b6;
    }
  }
  td {
    padding: 18px 34px 27px 33px;
    ${({ theme }) => theme.adaptive.main.xl} {
      padding: 29px 31px 27px 33px;
    }
    ${({ theme }) => theme.adaptive.main.lg} {
      padding: 21px 29px 16px 24px;
    }
    ${({ theme }) => theme.adaptive.main.slg} {
      padding: 17px 21px 10px 18px;
    }
    ${({ theme }) => theme.adaptive.main.md} {
      padding: 12px 20px 9px 15px;
    }
    &:empty {
      position: relative;
      z-index: 2;
      /* border-bottom: 1px solid #e4e3e5 !important; */
      /* border-bottom: 1px solid red !important; */
      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: #e4e3e5;
      }
    }
  }
  ${roadmapList}
`

const StageTitle = styled.div`
  font-weight: 400;
  font-size: 31px;
  line-height: 100%;
  color: #ffffff;
  letter-spacing: -0.04em;
  text-transform: uppercase;
  margin-bottom: 11px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 24px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 20px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 15px;
    margin-bottom: 5px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 18px;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    flex-shrink: 0;
  }
`

const StageDesc = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 114%;
  letter-spacing: -0.03em;
  color: #ffffff;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 18px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 15px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    font-size: 15px;
    margin-left: 20px;
    max-width: 180px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    max-width: 100%;
  }
`

const TdTitle = styled.div`
  font-size: 20px;
  line-height: 114%;
  letter-spacing: -0.03em;
  color: #000000;
  margin-bottom: 12px;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 18px;
    margin-bottom: 9px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 16px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 14px;
    margin-bottom: 8px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    font-size: 14px;
    margin-bottom: 12px;
  }
`

const TableLine = styled.div<{ type: TableLinesType }>`
  height: ${({ type }) => (type === TableLinesType.Large ? '27px' : '16px')};
  margin-bottom: ${({ type }) =>
    type === TableLinesType.Large ? '18px' : '7px'};
  width: 100%;
  background: #ffffff;
  opacity: 0.1;
  border-radius: 3px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: ${({ type }) => (type === TableLinesType.Large ? '24px' : '14px')};
    margin-bottom: ${({ type }) =>
      type === TableLinesType.Large ? '15px' : '7px'};
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: ${({ type }) => (type === TableLinesType.Large ? '20px' : '12px')};
    margin-bottom: ${({ type }) =>
      type === TableLinesType.Large ? '14px' : '5px'};
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: ${({ type }) => (type === TableLinesType.Large ? '17px' : '10px')};
    margin-bottom: ${({ type }) =>
      type === TableLinesType.Large ? '12px' : '5px'};
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: ${({ type }) => (type === TableLinesType.Large ? '13px' : '8px')};
    margin-bottom: ${({ type }) =>
      type === TableLinesType.Large ? '9px' : '3px'};
  }
`

const TableHeadLines = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 122px;
  background: #647bf7;
  top: 0;
  left: 0;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 107px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 92px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 79px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 72px;
  }
  &:nth-child(1) {
    ${TableLine} {
      &:nth-child(1) {
        margin-left: -107px;
        ${({ theme }) => theme.adaptive.main.lg} {
          margin-left: -81px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          margin-left: -69px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          margin-left: -37px;
        }
      }
      &:nth-child(2) {
        margin-left: -40px;
        ${({ theme }) => theme.adaptive.main.lg} {
          margin-left: -31px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          margin-left: -26px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          margin-left: -4px;
        }
      }
      &:nth-child(3) {
        margin-left: -83px;
        ${({ theme }) => theme.adaptive.main.lg} {
          margin-left: -63px;
        }
        ${({ theme }) => theme.adaptive.main.slg} {
          margin-left: -53px;
        }
        ${({ theme }) => theme.adaptive.main.md} {
          margin-left: -25px;
        }
      }
    }
  }
  &:nth-child(3) {
    ${TableLine} {
      margin-left: 37px;
      ${({ theme }) => theme.adaptive.main.lg} {
        margin-left: 27px;
      }
      ${({ theme }) => theme.adaptive.main.slg} {
        margin-left: 24px;
      }
      ${({ theme }) => theme.adaptive.main.md} {
        margin-left: 19px;
      }
    }
  }
`

const TableHeadRow = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  ${Container} {
    flex-shrink: 0;
  }
`

const RowsHeader = styled.div`
  height: 59px;
  background: #647bf7;
  ${Container} {
    display: flex;
    align-items: center;
    height: 100%;
  }
`
const RowsContent = styled.div`
  ${TdTitle} + ul {
    margin-bottom: 16px;
  }
  ${roadmapList}
  padding: 20px 0;
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 16px 0;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    ${({ theme }) => theme.adaptive.main.sm} {
      display: block;
    }
    li {
      width: 50%;
      &:nth-child(2n) {
        padding-left: 10px;
      }
      &:nth-child(2n - 1) {
        padding-right: 10px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const BoxesImage = styled.img`
  height: 444px;
  position: absolute;
  left: -324px;
  bottom: -66px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 389px;
    left: -264px;
    bottom: 84px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 333px;
    left: -244px;
    bottom: 64px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 287px;
    left: -204px;
    bottom: 114px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    height: 212px;
    left: -144px;
    bottom: 164px;
  }
`

const EngineImage = styled.img`
  height: 550px;
  position: absolute;
  right: -490px;
  top: 123px;
  ${({ theme }) => theme.adaptive.main.xl} {
    height: 480px;
    right: -420px;
    top: 163px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    height: 412px;
    right: -360px;
    top: 143px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    height: 355px;
    right: -313px;
    top: 133px;
  }
`
