export const API_URL = process.env.REACT_APP_API_URL
export const API_BASIC_AUTH_LOGIN = process.env.REACT_APP_AUTH_LOGIN
export const API_BASIC_AUTH_PASS = process.env.REACT_APP_AUTH_PASS
export const DECK = 'https://docsend.com/view/3uxm4wqv3hfiac3m'
export const MANIFESTO = 'https://docsend.com/view/6fnwyuiayixpe49r'
export const FAQ_LINK = 'https://hackmd.io/@hstarterfaq/Guide'
export const APPLY_FOR_IDO =
  'https://docs.google.com/forms/d/1PWCBgLH0bQoGjOOfbY8YQ73PDYAXQRk8543fiCqo128/viewform?ts=62a3008c&edit_requested=true'
export const YOUTUBE_LINK = 'https://tinyurl.com/HederaYT'
export const MEDIUM_LINK = 'https://medium.com/hedera-blog'
export const NFT_LINK = 'https://hederastarternft.com/'
export const DISCORD_LINK = 'https://discord.gg/YvCm33Nkwu'
export const TWITTER_LINK = 'https://twitter.com/HStarterio'
export const TELEGRAM_LINK = 'https://t.me/HStarterCommunity'
