import React from 'react'
import styled from 'styled-components'
import Footer from '../Footer'

interface Props {
  children: React.ReactNode
}

export default function PageWrapper(props: Props) {
  return (
    <Wrapper>
      <Content>{props.children}</Content>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #e6e5e8;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
`
