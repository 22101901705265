import React, { useState } from 'react'
import styled from 'styled-components'
import PageWrapper from '../PageWrapper/PageWrapper'
import Header from 'components/Header'
import Main from './Main'
import About from './About'
import Tiers from './Tiers'
import Roadmap from './Roadmap'
import Footer from './Footer'
//import Partners from './Partners'
//import Team from './Team'
import News from './News'
import ContactForm from '../Forms/ContactForm'

const Index: React.FunctionComponent = () => {
  const [isFormOpen, setIsFormOpen] = useState(false)

  const openClick = (val: boolean) => {
    setIsFormOpen(val)
  }

  return (
    <Root>
      <PageWrapper>
        <FirstScreenWrapper>
          <Header isOpen={isFormOpen} openClick={openClick} />
          <Main openClick={openClick} />
        </FirstScreenWrapper>
        <About />
        <Tiers />
        <Roadmap />
        {/*<Team />*/}
        <News />
        {/*<Partners />*/}
        <Footer />
      </PageWrapper>
      <ContactForm onClose={() => setIsFormOpen(false)} open={isFormOpen} />
    </Root>
  )
}

export default Index

const Root = styled.div`
  position: relative;
  width: 100%;
  /* height: 100%; */
  overflow: hidden;
`

const FirstScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`
