import axios from 'axios'
import apiAuth from '../authData'

const subscribeEmail = (email: string) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/subscribe`,
    {
      email,
    },
    apiAuth
  )
}

export default subscribeEmail
