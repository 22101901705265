let apiAuth: {
  auth?: {
    username: string
    password: string
  }
} = {}

if (process.env.REACT_APP_AUTH_LOGIN && process.env.REACT_APP_AUTH_PASS) {
  apiAuth = {
    auth: {
      username: process.env.REACT_APP_AUTH_LOGIN,
      password: process.env.REACT_APP_AUTH_PASS,
    },
  }
}

export default apiAuth
