import React, { useState } from 'react'
import styled from 'styled-components'
import Logo from '../Icons/Logo'
import HeaderBurger from './HeaderBurger'
import useWindowSize from '../../helpers/utils/useWindowSize'
//import ContactForm from '../Forms/ContactForm'
import { APPLY_FOR_IDO, DECK, FAQ_LINK } from 'configs/app'
import { Container } from 'theme/globalStyle'

interface DrawerProps {
  open: boolean
}

interface HeaderProps {
  openClick: (val: boolean) => void
  isOpen: boolean
}

const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const { width } = useWindowSize()

  const onBurgerClick = () => {
    setIsOpen(!isOpen)
  }

  const menuItems = (
    <NavList>
      <LinkWrap>
        <a href={FAQ_LINK} target="_blank" rel="noreferrer">
          FAQ
        </a>
      </LinkWrap>
      <LinkWrap>
        <a href={APPLY_FOR_IDO} target="_blank" rel="noreferrer">
          Apply for IDO
        </a>
      </LinkWrap>
      <LinkWrap>
        <a href={DECK} target="_blank" rel="noreferrer">
          Deck
        </a>
      </LinkWrap>
      {/*<LinkWrap>*/}
      {/*  <a href={MANIFESTO} target="_blank" rel="noreferrer">*/}
      {/*    Manifesto*/}
      {/*  </a>*/}
      {/*</LinkWrap>*/}
      {/* <LinkWrap>
        <a href={'/'}>WhitePaper</a>
      </LinkWrap>
      <LinkWrap>
        <a href={'/'}>Faq</a>
      </LinkWrap> */}
      <LinkWrap>
        <button type="button" onClick={() => props.openClick(true)}>
          Contact
        </button>
      </LinkWrap>
    </NavList>
  )

  const MobileMenu = (
    <>
      <HeaderBurger onBurgerClick={onBurgerClick} active={isOpen} />
      <DrawerWrap>
        <Drawer open={isOpen}>
          <DrawerContainer>{menuItems}</DrawerContainer>
        </Drawer>
      </DrawerWrap>
    </>
  )

  return (
    <Container>
      <Root>
        <LogoWrap open={isOpen}>
          <Logo open={props.isOpen} />
        </LogoWrap>
        {(() => {
          if (width <= 768) {
            return MobileMenu
          }
          return menuItems
        })()}
      </Root>
    </Container>
  )
}

export default Header

const Root = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  z-index: 6;
  ${({ theme }) => theme.adaptive.main.xl} {
    padding: 21px 0;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    padding: 15px 0;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    padding: 12px 0;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    padding: 18px 0;
  }
`

const LogoWrap = styled.div<DrawerProps>`
  width: 176px;
  height: 32px;
  z-index: 6;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 169px;
    height: 30px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 146px;
    height: 26px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 121px;
    height: 21px;
  }
  svg {
    width: 100%;
    height: 100%;
    fill: ${({ open }) => (open ? '#fff' : '#000')};
    transition: 0.4s;
  }
  &:hover {
    svg {
      fill: #6078ff;
    }
  }
`

const NavList = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const LinkWrap = styled.div`
  @media screen and (max-width: 768px) {
    position: relative;
    padding-top: 11px;
    padding-bottom: 21px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 1px;
      border-bottom: 1px dashed #ffffff;
    }
    &:last-child {
      &:before {
        content: unset;
      }
    }
  }
  a,
  button {
    padding: 5px 20px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #000000;
    transition: 0.4s;
    border: 1px solid transparent;
    border-radius: 12px;
    cursor: pointer;
    outline: none;
    ${({ theme }) => theme.adaptive.main.md} {
      padding: 4px 10px;
      font-size: 11px;
    }
    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
      padding: 0;
      color: #ffffff;
    }

    &:hover {
      border: 1px solid #000000;

      /* ${({ theme }) => theme.adaptive.main.sm} {
        border: 0;
      } */
    }
  }
`

const DrawerWrap = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
`

const Drawer = styled.div<DrawerProps>`
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #6078ff;
  transition: 0.4s;
  left: ${({ open }) => (open ? '0' : '-105%')};
  top: 0;
  z-index: 1;

  /* ${({ theme }) => theme.adaptive.main.sm} {
    min-height: 100%;
  } */
`

const DrawerContainer = styled.div`
  display: flex;
  height: 100%;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  /* ${({ theme }) => theme.adaptive.main.sm} {
    overflow: auto;
  }

  ${({ theme }) => theme.adaptive.main.smm} {
    padding: 20px;
  } */
`
