import React from 'react'
import styled from 'styled-components'
import tiersList from '../../../store/tiers'

interface dotsProps {
  activeId: number
}

const Dots: React.FunctionComponent<dotsProps> = (props) => {
  return (
    <Root>
      {tiersList.map((tier, idxNumber) => (
        <Dot active={idxNumber === props.activeId} key={idxNumber} />
      ))}
    </Root>
  )
}

export default Dots

const Root = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

interface dotProps {
  active: boolean
}

const Dot = styled.div<dotProps>`
  width: ${({ active }) => (active ? '6px' : '4px')};
  height: ${({ active }) => (active ? '6px' : '4px')};
  background: ${({ active }) => (active ? '#6078FF' : '#C4BEC7')};
  border-radius: 50%;
  transition: 0.4s;
  margin: 0 2px;
`
