import React, { useState } from 'react'
import styled from 'styled-components'
import subscribeRequest from 'api/mail/subscribeEmail'

function Form() {
  const [error, setError] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)

  const clearForm = () => {
    setEmail('')
  }

  const validateEmail = (email: string) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  const onButtonClick = () => {
    if (!validateEmail(email)) return setError('Email is invalid')
    subscribeRequest(email)
      .then(() => {
        setIsSuccess(true)
        setTimeout(() => {
          setIsSuccess(false)
          clearForm()
        }, 4000)
      })
      .catch((error: any) => {
        console.log(error)
        error.response.data.message
          ? setError(error.response.data.message)
          : clearForm()
      })
      .finally(() => {
        setDisabled(false)
      })
  }

  return (
    <Root>
      <InputWrapper>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          disabled={disabled || isSuccess}
          value={email}
        />
        {error && <Error>{error}</Error>}
      </InputWrapper>
      <SubscribeBtn
        disabled={disabled || isSuccess}
        onClick={onButtonClick}
        isSuccess={isSuccess}
      >
        <SubscribeBtnText>
          {isSuccess ? 'Subscribed!' : 'Subscribe'}
        </SubscribeBtnText>
      </SubscribeBtn>
    </Root>
  )
}

export default Form

const Root = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`

const InputWrapper = styled.div`
  position: relative;
  margin-right: 44px;
  ${({ theme }) => theme.adaptive.main.xl} {
    margin-right: 25px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    margin-right: 20px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    margin-right: 40px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    margin-right: 26px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    margin-right: 37px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    margin-right: 18px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    margin-right: 14px;
  }
`

const Error = styled.p`
  position: absolute;
  right: 0;
  bottom: -19px;
  color: #ff0000;
  font-size: 14px;
  letter-spacing: -0.2px;
  ${({ theme }) => theme.adaptive.main.xl} {
    bottom: -16px;
    font-size: 12px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    bottom: -13px;
    font-size: 10px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    bottom: -11px;
    font-size: 9px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    bottom: -10px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    bottom: -12px;
    font-size: 10px;
  }
`

const Input = styled.input`
  display: block;
  width: 209px;
  border: 0;
  border-bottom: 1px solid #b6b6b6;
  outline: none;
  padding-left: 10px;
  border-radius: 0;
  transition: 0.4s;

  font-style: italic;
  font-weight: 300;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: -0.03em;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.5;
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 183px;

    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 199px;

    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 171px;

    font-size: 14px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 181px;
  }
  ${({ theme }) => theme.adaptive.main.smd} {
    width: 399px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 208px;
  }
  ${({ theme }) => theme.adaptive.main.xsm} {
    width: 159px;
  }
  &:focus {
    border-bottom: 1px solid #6078ff;
    color: #6078ff;
  }
`

const SubscribeBtnText = styled.div`
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -0.03em;
  ${({ theme }) => theme.adaptive.main.xl} {
    font-size: 20px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    font-size: 16px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    font-size: 14px;
  }
`

const SubscribeBtn = styled.button<{ isSuccess: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 166px;
  height: 43px;
  border: ${({ isSuccess }) => (isSuccess ? '' : '1px solid #000000')};
  background: ${({ isSuccess }) => (isSuccess ? 'none' : '')};
  border-radius: 61px;
  z-index: 2;
  transition: 0.4s;
  ${SubscribeBtnText} {
    color: ${({ isSuccess }) => (isSuccess ? '#9C9B9D' : '#000')};
  }
  ${({ theme }) => theme.adaptive.main.xl} {
    width: 145px;
    height: 38px;
    border-radius: 53px;
  }
  ${({ theme }) => theme.adaptive.main.lg} {
    width: 125px;
    height: 32px;
    border-radius: 45px;
  }
  ${({ theme }) => theme.adaptive.main.slg} {
    width: 107px;
    height: 27px;
    border-radius: 39px;
  }
  ${({ theme }) => theme.adaptive.main.md} {
    width: 92px;
    height: 23px;
    border-radius: 34px;
  }
  ${({ theme }) => theme.adaptive.main.sm} {
    width: 108px;
    height: 28px;
    border-radius: 40px;
  }
  &:hover {
    &:not(&:disabled) {
      background: #fff;
      border: 1px solid #fff;
    }
  }
  &:disabled {
    background: #e7e6e8;
    color: #a2a1a2;
  }
`
