import { Base64 } from 'js-base64'
import { API_BASIC_AUTH_LOGIN, API_BASIC_AUTH_PASS } from '../../configs/app'
import { SendEmailParams } from '../../types/mail/mailTypes'

interface IOptions {
  method: string
  headers: {
    Authorization?: string
    'Content-Type': string
  }
  body: string
}

export const getMailPostOptions = (data: SendEmailParams) => {
  const options: IOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }

  if (API_BASIC_AUTH_LOGIN && API_BASIC_AUTH_PASS) {
    const authString = `${API_BASIC_AUTH_LOGIN}:${API_BASIC_AUTH_PASS}`
    options.headers.Authorization = `Basic ${Base64.encode(authString)}`
  }

  return options
}
