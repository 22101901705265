import Tier1Image from 'images/index/tiers/tiers/1.png'
import Tier2Image from 'images/index/tiers/tiers/2.png'
import Tier3Image from 'images/index/tiers/tiers/3.png'
import Tier4Image from 'images/index/tiers/tiers/4.png'
import Tier5Image from 'images/index/tiers/tiers/5.png'

interface Tier {
  name: string
  stakeAmount: string
  participation: string
  poolWeight: string
  image: string
}

const tiersList: Tier[] = [
  {
    name: 'Fire',
    stakeAmount: '50',
    participation: '1 Ticket',
    poolWeight: '1.0',
    image: Tier5Image,
  },
  {
    name: 'Printing Press',
    stakeAmount: '1’000',
    participation: '25 Ticket',
    poolWeight: '1.3',
    image: Tier4Image,
  },
  {
    name: 'Electricity',
    stakeAmount: '4’000',
    participation: '125 Ticket',
    poolWeight: '1.7',
    image: Tier3Image,
  },
  {
    name: 'internet',
    stakeAmount: '12’000',
    participation: '400 Ticket',
    poolWeight: '2.2',
    image: Tier2Image,
  },
  {
    name: 'blockchain',
    stakeAmount: '36’000',
    participation: 'GUARANTEED',
    poolWeight: '3.0',
    image: Tier1Image,
  },
]

export default tiersList
