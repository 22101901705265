import React from 'react'
import Youtube from './Youtube'
import Medium from './Medium'

function News() {
  return (
    <>
      <Medium />
      <Youtube />
    </>
  )
}

export default News
